<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">On-Duty hours</span>
    <button
      mat-button
      [disabled]="!form.valid || loading"
      [class.save-disabled]="!form.valid || loading"
      class="header-action-button"
      (click)="onSubmit()"
      data-test="save-button"
    >
      Save
    </button>
  </div>
  <div class="content">
    <div *ngIf="loading; else showContent">
      <div class="margin-left-sm margin-right-sm">
        <ngx-skeleton-loader count="6" appearance="line" [theme]="{ height: '30px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" [theme]="{ height: '120px' }"></ngx-skeleton-loader>
      </div>
    </div>
    <ng-template #showContent>
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="15px">
        <div fxFlex="10" class="description-banner">
          <div class="description">
            <span>Please enter ON-DUTY hours for the last {{ workHourHistory.length }} days</span>
          </div>
        </div>
        <div fxFLex="70" fxLayout="column" class="dates-container">
          <div fxFlex="10" class="duty-date" fxLayout *ngFor="let workHour of workHourHistory; let i = index">
            <div fxFLex="50" class="date-label">{{ workHour.date | date: 'dd MMM YYYY' | uppercase }}</div>
            <div fxFLex="50">
              <mat-form-field class="full-width" data-test="hours-worked">
                <mat-label> Hours worked </mat-label>
                <mat-select [formControlName]="getFormControlName(i)" data-test="hours-work">
                  <mat-option *ngFor="let hour of hoursCombo" [value]="hour" data-test="working-hours">
                    {{ hour }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxFlex="20" fxLayout="column" class="consent">
          <div fxFlex="70">
            I hereby certify that the information given above is correct to the best of my knowledge and belief; and
            that I was last relieved from work at
          </div>
          <div fxFlex="30" class="consent-date">
            <mat-form-field class="full-width text-grayed">
              <input matInput autocomplete="off" formControlName="certifyCreatedAt" readonly />
              <mat-icon matSuffix svgIcon="calendar-line"></mat-icon>
            </mat-form-field>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>
