<div class="injured-details-container">
    <h2 class="mat-h2">Witness Details</h2>
    <form [formGroup]="form">
      <mat-accordion>
        <div formArrayName="witnessDetails">
          <mat-expansion-panel *ngFor="let person of witnessDetails.controls; let i = index" [formGroupName]="i">
            <mat-expansion-panel-header>
              <mat-panel-title> Witness {{ i + 1 }} </mat-panel-title>
              <mat-panel-description>
                <button mat-icon-button (click)="removeRow(i)" aria-label="Remove Injured Person">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
  
            <!-- Name Field -->
            <mat-form-field appearance="outline" class="form-field full-width">
              <mat-label>Name</mat-label>
              <input matInput formControlName="witnessName" placeholder="Name" />
            </mat-form-field>
  
            <!-- Address Field -->
            <mat-form-field appearance="outline" class="form-field full-width">
              <mat-label>Address</mat-label>
              <input matInput formControlName="witnessAddress" placeholder="Address" />
            </mat-form-field>
  
            <!-- Contact Number Field -->
            <mat-form-field appearance="outline" class="form-field full-width">
              <mat-label>Contact Number</mat-label>
              <input matInput formControlName="witnessContactNumber"
              placeholder="Contact Number" />
            </mat-form-field>
  
            <!-- Type Field -->
            <mat-radio-group formControlName="type" class="radio-group">
              <mat-radio-button value="insured">Insured Vehicle</mat-radio-button>
              <mat-radio-button value="tp">TP Vehicle</mat-radio-button>
            </mat-radio-group>
  
            <!-- Description Field -->
            <mat-form-field appearance="outline" class="form-field full-width margin-top-sm">
              <mat-label>Other (Specify)</mat-label>
              <textarea
                matInput
                formControlName="witnessDescription"
                placeholder="Other"
                maxlength="200"
                matTextareaAutosize
                matAutosizeMinRows="6"
              ></textarea>
            </mat-form-field>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
  
      <button mat-raised-button color="primary" (click)="addRow()" class="add-row">
        + Add Witness
      </button>
    </form>
  </div>
  