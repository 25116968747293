<div class="property-damage-container">
  <h2 class="mat-h2">Property Damage / Other Vehicle Details</h2>
  <form [formGroup]="form">
    <mat-accordion>
      <div formArrayName="propertyDamages">
        <mat-expansion-panel *ngFor="let damage of propertyDamages.controls; let i = index" [formGroupName]="i">
          <mat-expansion-panel-header>
            <mat-panel-title> Property Damage {{ i + 1 }} </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="removePropertyDamage(i)" aria-label="Remove Property Damage">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Property Description Field -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Describe Property (If Auto: Yr, Make, Model, Plate)</mat-label>
            <textarea
              matInput
              formControlName="propertyDesc"
              placeholder="Description"
              maxlength="200"
              data-test="propertyDesc-form-field"
              matTextareaAutosize
              matAutosizeMinRows="6"
            ></textarea>
          </mat-form-field>

          <!-- Third Party Owner/Driver Name and Address -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Third Party Owner/Driver Name and Address</mat-label>
            <textarea
              matInput
              formControlName="driverNameAddress"
              placeholder="Driver Name And Address"
              maxlength="200"
              data-test="driverNameAddress-form-field"
              matTextareaAutosize
              matAutosizeMinRows="6"
            ></textarea>
          </mat-form-field>

          <!-- Third Party Owner/Driver Phone -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Third Party Owner/Driver Phone</mat-label>
            <input
              matInput
              formControlName="driverPhone"
              placeholder="Driver Phone"
              data-test="driverPhone-form-field"
            />
          </mat-form-field>

          <!-- Third Party Driver License -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Third Party Driver License</mat-label>
            <input
              matInput
              formControlName="driverLicense"
              placeholder="Driver License"
              data-test="driverLicense-form-field"
            />
          </mat-form-field>

          <!-- Third Party Owner/Driver Insurance Number -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Third Party Owner/Driver Insurance Number</mat-label>
            <input
              matInput
              formControlName="driverInsuranceNumber"
              placeholder="Insurance Number"
              data-test="driverInsuranceNumber-form-field"
            />
          </mat-form-field>

          <!-- Description of Damage -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Description of Damage / Injury</mat-label>
            <textarea
              matInput
              formControlName="damageDesc"
              placeholder="Description Of Damage"
              maxlength="200"
              data-test="damageDesc-form-field"
              matTextareaAutosize
              matAutosizeMinRows="6"
            ></textarea>
          </mat-form-field>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

    <button mat-raised-button color="primary" (click)="addPropertyDamage()" class="add-row">
      + Add Property Damage
    </button>
  </form>
</div>
