<div class="table-container-2">
    <h2 class="mat-h2">Loss Details</h2>
    <form [formGroup]="form" class=" mt-4">
      <div class="form-container">
        <div class="field-container">
          <!-- <div class="field-label"></div> -->
          <div>
            <mat-label>Loss Date:</mat-label>
            <mat-form-field class="full-width" appearance="outline">
              <input
                matInput
                formControlName="accidentDate"
                data-test="accidentDate-form-field"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Loss Time:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                formControlName="accidentTime"
                data-test="accidentTime-form-field"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Loss Location:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                formControlName="accidentLocation"
                data-test="accidentLocation-form-field"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Loss Description:</div>
          <div>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                formControlName="description"
                maxlength="200"
                data-test="description-form-field"
                matTextareaAutosize
                matAutosizeMinRows="6"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Conditions:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input matInput formControlName="conditions" data-test="conditions-form-field" />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Weather:</div>
          <div class="margin-top-md">
            <div fxLayout class="flex flex-row checkbox-group">
              <mat-checkbox fxFlex="33" formControlName="weatherClear">Clear</mat-checkbox>
              <mat-checkbox fxFlex="33" formControlName="weatherCloudy">Cloudy</mat-checkbox>
              <mat-checkbox fxFlex="33" formControlName="weatherFog">Fog</mat-checkbox>
            </div>
            <div fxLayout class="flex flex-row checkbox-group">
              <mat-checkbox fxFlex="33" formControlName="weatherSleet">Sleet</mat-checkbox>
              <mat-checkbox fxFlex="33" formControlName="weatherSnow">Snow</mat-checkbox>
              <mat-checkbox fxFlex="33" formControlName="weatherRain">Rain</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>