import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  constructor() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advanced);
  }

  getLocalDateTime(utcDateTime: string): string {
    return dayjs
      .utc(utcDateTime, 'YYYY-MM-DD')
      .local()
      .format();
  }

  getConvertedDateAndTime(utcDateFrom: string, utcDateTo: string): string {
    if (utcDateFrom) {
      const ConvertedDateFrom = dayjs.utc(utcDateFrom).tz('America/New_York');
      const ConvertedDateTo = utcDateTo ? dayjs.utc(utcDateTo).tz('America/New_York') : undefined;

      return this.getFormattedDateTime(ConvertedDateFrom, ConvertedDateFrom, ConvertedDateTo);
    }
  }
  getConvertedFromToDateTime(utcDateFrom: string, utcDateTo: string): string {
    if (utcDateFrom) {
      const ConvertedDateFrom = dayjs.utc(utcDateFrom).tz('America/New_York');
      const ConvertedDateTo = utcDateTo ? dayjs.utc(utcDateTo).tz('America/New_York') : undefined;

      return this.getFormattedDateFromToTime(ConvertedDateFrom, ConvertedDateFrom, ConvertedDateTo);
    }
  }

  getUTCDate(): Date {
    return dayjs.utc().toDate();
  }

  getUTCDateFormatted(): string {
    return dayjs.utc().format();
  }

  subtractMilliseconds(date: Date, milliseconds: number): string {
    return dayjs(date)
      .subtract(milliseconds, 'ms')
      .format();
  }

  subtractDates(date1: string, date2: string): number {
    return dayjs(date2).diff(dayjs(date1));
  }

  getFormattedDate(dateTime: string): Date {
    return dayjs
      .utc(dateTime)
      .tz('America/New_York')
      .toDate();
  }

  getConvertedDateTimeFromUTC(dateTime: string): string {
    return dayjs
      .utc(dateTime)
      .tz('America/New_York')
      .format('MMM DD,YYYY hh:mm a z');
  }

  getConvertedDateFromUTC(utcDate: string): string {
    return dayjs
      .utc(utcDate.substring(0, 23), 'DD-MMM-YYYY')
      .tz('America/New_York')
      .toString();
  }

  getCurrentMoment(): dayjs.Dayjs {
    return dayjs().tz('America/New_York');
  }

  getFormatedStartAndEndDate(utcStartDate: string, utcEndDate: string): string {
    if (utcStartDate && utcEndDate) {
      return `${dayjs
        .utc(utcStartDate)
        .tz('America/New_York')
        .format('MMM DD YYYY')} - ${dayjs
        .utc(utcEndDate)
        .tz('America/New_York')
        .format('MMM DD YYYY')}`;
    }
  }

  getFormatedConvertedUTCDate(utcDate: string): string {
    return dayjs
      .utc(utcDate)
      .tz('America/New_York')
      .format('MMM DD, YYYY')
      .toString();
  }

  getFormatedConvertedUTCTime(utcDateFrom: string, utcDateTo: string): string {
    if (utcDateFrom) {
      const ConvertedDateFrom = dayjs.utc(utcDateFrom).tz('America/New_York');
      const ConvertedDateTo = utcDateTo ? dayjs.utc(utcDateTo).tz('America/New_York') : undefined;

      return this.getFormattedTime(ConvertedDateFrom, ConvertedDateTo);
    }
  }

  formatDateTime(date: string, format: string): string {
    return dayjs(date)
      .utc()
      .format(format);
  }
  
  formattedDateTime(date: string, format: string): string {
    return dayjs(date)
      .utc()
      .tz('America/New_York')
      .format(format);
  }

  private getFormattedTime(fromTime: dayjs.Dayjs, toTime: dayjs.Dayjs): string {
    if (fromTime && toTime) {
      return `${fromTime.format('hh:mm a')} - ${toTime.format('hh:mm a z')}`;
    } else if (fromTime) {
      return `${fromTime.format('hh:mm a z')}`;
    } else {
      return '';
    }
  }
  private getFormattedDateTime(date: dayjs.Dayjs, fromTime: dayjs.Dayjs, toTime: dayjs.Dayjs): string {
    if (date && fromTime && toTime) {
      return `${date.format('MMM DD YYYY')} , ${fromTime.format('hh:mm a')} - ${toTime.format('hh:mm a z')}`;
    } else if (date && toTime) {
      return `${date.format('MMM DD YYYY')} , by ${toTime.format('hh:mm a z')}`;
    } else if (date && fromTime) {
      return `${date.format('MMM DD YYYY')} , ${fromTime.format('hh:mm a z')}`;
    } else if (date) {
      return `${date.format('MMM DD YYYY')}`;
    } else {
      return '';
    }
  }
  private getFormattedDateFromToTime(date: dayjs.Dayjs, fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs): string {
    const fromTime = fromDate.format('hh:mm a');
    const toTime = toDate ? toDate.format('hh:mm a') : undefined;
    if (date && fromTime && !toTime) {
      return `${date.format('MMM DD YYYY')}, ${fromTime}`;
    } else if (date && fromTime !== toTime) {
      return `${date.format('MMM DD YYYY')} , ${fromTime} to ${toTime}`;
    } else if (date && fromTime === toTime) {
      return `${date.format('MMM DD YYYY')} , ${fromTime}`;
    } else if (date) {
      return `${date.format('MMM DD YYYY')}`;
    } else {
      return '';
    }
  }
}
