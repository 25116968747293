<div fxLayout="column" class="main-container">
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <div fxLayout="column" class="message" *ngIf="isApiLoading">
    <div fxFlex="40" class="loading-gif"><img src="/assets/icons/fleetoperate-dots-loading.gif" /></div>
    <div fxFlex="40"><span class="upload-message">Upload in progress... Please wait and don’t refresh the screen.</span></div>
    <div fxFlex="20"></div>
  </div>
  <ng-container *ngIf="!isApiLoading">
    <div class="title margin-top-lg nargin-bottom-lg">
      License Upload
    </div>
    <div class="options margin-top-md margin-left-sm" *ngIf="finalImageFiles?.length != 2">
      <div class="margin-bottom-sm">
        <button mat-button (click)="openGallery(galleryImageInput)">
          <mat-icon svgIcon="image-upload"></mat-icon>
          <span *ngIf="!isFrontSave">Upload License Front Image</span>
          <span *ngIf="isFrontSave">Upload License Back Image</span>
        </button>
        <input
          #galleryImageInput
          type="file"
          name="image"
          (change)="onProcessPictureFile($event)"
          [accept]="acceptTypesPicture"
          data-test="add-photo"
          style="display: none;"
        />
      </div>
      <div class="margin-bottom-sm" *ngIf="!finalImageFiles?.length > 0">
        <button mat-button (click)="openFileExplorer()">
          <mat-icon svgIcon="icon-file-blue"></mat-icon>
          <span>Upload File</span>
        </button>
      </div>
    </div>
    <span class="center margin-top-sm margin-bottom-sm disclaimer-text"
      >Data extraction will be performed on the JPEG, JPG and PNG document types.</span
    >
  </ng-container>
  <div *ngIf="!isApiLoading">
    <div class="content" *ngIf="showCrop">
      <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
        <image-cropper
          #previewImage
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [imageQuality]="100"
          cropperMinWidth="420"
          cropperMinHeight="220"
          [aspectRatio]="4 / 4"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [format]="imageFormat"
          (imageCropped)="onImageCropped($event)"
          (imageLoaded)="onImageLoaded()"
          (cropperReady)="onCropperReady()"
          (loadImageFailed)="onLoadImageFailed()"
        >
        </image-cropper>

        <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
          <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
            <button
              class="margin-bottom secondary-button"
              mat-raised-button
              (click)="onCancelCrop()"
              data-test="cancel-button"
              [disabled]="isApiLoading"
            >
              Cancel
            </button>
            <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
              <mat-icon>rotate_left</mat-icon>
            </button>
            <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
              <mat-icon>rotate_right</mat-icon>
            </button>
            <button
              class="margin-bottom primary-button finish-crop-button"
              mat-raised-button
              (click)="onFinishCrop()"
              data-test="finish-button"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="!isApiLoading">
    <div fxFlex="80" class="text-align-center" fxLayout="column" fxLayoutGap="4px" *ngIf="finalImageFiles?.length == 2">
      <div fxFlex="45" fxLayout="column">
        <span class="margin-bottom-md margin-top-md">Front Image:</span>
        <img [src]="frontImageUrl" class="license-image" />
      </div>
      <div class="margin-top-md" fxFlex="45" fxLayout="column">
        <span class="margin-bottom-md">Back Image:</span>
        <img [src]="backImageUrl" class="license-image" />
      </div>
    </div>
    <div fxFlex="20" class="image-action-row margin-top-lg" fxLayout *ngIf="finalImageFiles?.length == 2">
      <button fxFlex="40" mat-button class="btn-reset" (click)="resetLicense()" [disabled]="isApiLoading">
        Reset
      </button>
      <button fxFlex="40" mat-button class="btn-save" (click)="saveLicense()" [disabled]="isApiLoading">
        Save
      </button>
    </div>
  </div>
</div>
