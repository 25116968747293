import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../registration/shared/registration.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DriverConsent } from 'src/app/registration/shared/models/driver-consent.model';

@Component({
  selector: 'app-profile-consent',
  templateUrl: './profile-access-consent.html',
  styleUrls: ['./profile-access-consent.scss']
})
export class ProfileAccessConsentComponent implements OnInit {
  code: string;
  consentId: string;
  loading = false;
  consentData: any;
  consentProcessed: boolean = false;
  consentStatusMessage: string = '';
  genericErrorMessage: string =
    'Unable to retrieve consent details. Either the link is broken or you might have already visited.';

  constructor(private route: ActivatedRoute, private readonly registrationService: RegistrationService) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.code = params.get('code');
      this.consentId = params.get('id');
      this.consentData = null;
      if (this.code && this.consentId) {
        this.getConsentDetails(this.consentId);
      }
    });
  }

  getConsentDetails(consentId: string): void {
    this.loading = true;
    this.registrationService
      .retriveConsentDetails(consentId, this.code)
      .pipe(
        tap((response: DriverConsent[]) => {
          this.loading = false;
          if (response) {
            this.consentData = response;
            if (['approved', 'denied'].includes(this.consentData?.status)) {
              this.consentData = null;
              this.consentStatusMessage = 'Response already submitted';
            }
          }
        }),
        catchError(error => {
          this.loading = false;
          this.consentData = null;
          this.consentStatusMessage = 'Unable to retrieve data please try again later.';
          return of(error);
        })
      )
      .subscribe();
  }

  onApproveConsent(): void {
    this.processConsentStatus('approved');
  }

  rejectConsent(): void {
    this.processConsentStatus('denied');
  }

  processConsentStatus(status: string): void {
    this.loading = true;
    const consentRequestId = this.consentData?.id;
    const consentPayload = {
      id: consentRequestId,
      status: status,
      approvalCode: this.code
    };

    this.registrationService
      .approveOrRejectConsent(consentPayload, consentRequestId, this.code)
      .pipe(
        tap(response => {
          this.loading = false;
          if (response) {
            this.consentProcessed = true;
            this.consentStatusMessage =
              status === 'approved'
                ? 'You have successfully approved the consent request.'
                : 'You have successfully rejected the consent request.';
          }
        }),
        catchError(error => {
          this.loading = false;
          this.consentProcessed = true;
          this.consentStatusMessage = 'An error occurred while processing your consent. Please try again later.';
          return of(error);
        })
      )
      .subscribe();
  }
}
