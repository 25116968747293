import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-property-damage-detail',
  templateUrl: './property-damage-detail.component.html',
  styleUrls: ['./property-damage-detail.component.scss']
})
export class PropertyDamageDetailComponent implements OnInit {
  form: FormGroup;

  constructor(private readonly fb: FormBuilder) {}
  ngOnInit(): void {
    //this.form = this.createForm();
    this.form = this.fb.group({
      propertyDamages: this.fb.array([this.createPropertyDamage()])
    });
  }

  // createForm(): FormGroup {
  //   return this.fb.group({
  //     propertyDesc: [''],
  //     driverNameAddress: [''],
  //     driverPhone: [''],
  //     driverLicense: [''],
  //     driverInsuranceNumber: [''],
  //     damageDesc: [''],
  //   });
  // }

  get propertyDamages(): FormArray {
    return this.form.get('propertyDamages') as FormArray;
  }

  createPropertyDamage(): FormGroup {
    return this.fb.group({
      propertyDesc: [''],
      driverNameAddress: [''],
      driverPhone: [''],
      driverLicense: [''],
      driverInsuranceNumber: [''],
      damageDesc: ['']
    });
  }

  addPropertyDamage(): void {
    this.propertyDamages.push(this.createPropertyDamage());
  }

  removePropertyDamage(index: number): void {
    if (this.propertyDamages.length > 1) {
      this.propertyDamages.removeAt(index);
    }
  }
}
