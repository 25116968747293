<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
    <div class="header-navigation-back">
      <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title" data-test="edit-button">Edit Profile</span>

      <button data-test="save-button" mat-button [disabled]="(!form.dirty || !form.valid) && !loading"
        [class.save-disabled]="(!form.dirty || !form.valid) && !loading" class="header-action-button"
        (click)="onSubmit()">
        Save
      </button>
    </div>
    <div class="content">
      <app-loading-gif *ngIf="loading"></app-loading-gif>
      <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center stretch"
        class="component-container padding-top-md" [hidden]="loading">
        <div fxFlex="20" fxLayout>
          <div class="profile-container" fxFlex="100">
            <img [src]="profilePicture" class="profile-image" />
            <mat-icon svgIcon="edit-icon" class="edit-icon" (click)="onNavigateToProfilePicture()"
              data-test="add-profile-pic"></mat-icon>
          </div>
        </div>
        <div fxFlex="10" class="personal-info-title">
          Personal Info
        </div>
        <div fxflex="30" fxLayout="column" class="personal-form-field" data-test="driver-info">
          <div fxFlex="25">
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" placeholder="First Name" formControlName="firstName" required
                data-test="name-form-field" />
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <mat-form-field class="full-width">
              <input matInput placeholder="Last Name" autocomplete="off" formControlName="lastName" required
                data-test="name-form-field" />
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <mat-form-field class="full-width">
              <input matInput placeholder="Date Of Birth" [matDatepicker]="dateOfBirth" formControlName="dateOfBirth"
                [max]="maxDate" required (click)="onDatePickerClick(dateOfBirth)" onkeydown="return false;" />
              <mat-datepicker-toggle matSuffix [for]="dateOfBirth" data-test="dob-form-field"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" #dateOfBirth></mat-datepicker>
              <mat-error>
                {{ getDateOfBirthErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <mat-form-field class="full-width">
              <mat-label>Gender*</mat-label>
              <mat-select formControlName="gender" data-test="gender-form-field">
                <mat-option value="male" data-test="gender-value">Male</mat-option>
                <mat-option value="female" data-test="gender-value">Female</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <mat-form-field class="full-width" [ngClass]="{ 'text-grayed': isEncrypted == true || isSinLoading }">
              <mat-label>SSN / SIN</mat-label>
              <input matInput appSinMask autocomplete="off" formControlName="sin" maxlength="11"
                data-test="sin-form-field" [readonly]="isEncrypted" />
              <ng-container matSuffix *ngIf="form.get('sin').value">
                <mat-icon matSuffix *ngIf="isEncrypted && !isSinLoading" (click)="decryptSin()" svgIcon="eye-line"
                  data-test="sin-decrypt" class="eye-line-icon"></mat-icon>
                <mat-icon matSuffix *ngIf="!isEncrypted && !isSinLoading" (click)="decryptSin()"
                  svgIcon="eye-off-line"></mat-icon>
              </ng-container>

              <mat-spinner matSuffix diameter="18" *ngIf="isSinLoading"></mat-spinner>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="10" class="personal-info-title">
          Contact
        </div>
        <div fxflex="30" fxLayout="column" class="contact-form-field">
          <!-- Google Address Lookup Toggle -->
          <div class="turnoff-text" (click)="onGoogleAddressLookupToggle($event)">
            {{ isGoogleAddressLookupEnabled ? 'Turn OFF address lookup' : 'Turn ON address lookup' }}
          </div>
          <!-- Google Address Lookup Enabled -->
          <ng-container *ngIf="isGoogleAddressLookupEnabled">
            <mat-form-field class="full-width">
              <input matInput placeholder="Address" formControlName="address" #addressText required
                data-test="address-field" autocomplete="on" />
              <mat-error *ngIf="address.touched && address.invalid">
                {{ getAddressErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </ng-container>
          <!-- Google Address Lookup Disabled -->
          <ng-container *ngIf="!isGoogleAddressLookupEnabled">
            <mat-form-field class="full-width">
              <input matInput placeholder="Street Address" formControlName="streetAddress" required />
              <mat-error *ngIf="form.get('streetAddress').touched && form.get('streetAddress').invalid">
                Street address is required.
              </mat-error>
            </mat-form-field>
            <!-- Country Dropdown -->
            <mat-form-field class="full-width">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" (selectionChange)="onCountryChange()" required>
                <mat-option *ngFor="let country of countries" [value]="country.value">
                  {{ country.key }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('country').touched && form.get('country').invalid">
                Country is required.
              </mat-error>
            </mat-form-field>

            <!-- Province Dropdown (State) -->
            <mat-form-field class="full-width">
              <mat-label>Province</mat-label>
              <mat-select formControlName="province" required>
                <mat-option *ngFor="let province of provinces" [value]="province.value">
                  {{ province.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('province').touched && form.get('province').invalid">
                Province is required.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input matInput placeholder="City" formControlName="city" required />
              <mat-error *ngIf="form.get('city').touched && form.get('city').invalid">
                City is required.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input matInput placeholder="Zip Code" formControlName="zipCode" required />
              <mat-error *ngIf="form.get('zipCode').touched && form.get('zipCode').invalid">
                Zip code is required and must be valid.
              </mat-error>
            </mat-form-field>
          </ng-container>
          <mat-form-field class="full-width">
            <mat-label>Work Status*</mat-label>
            <mat-select formControlName="workStatus" data-test="workStatus-form-field">
              <mat-option *ngFor="let status of workStatues" [value]="status.name" data-test="workStatus-value">{{
                status.displayName
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Phone*</mat-label>
            <input matInput autocomplete="off" placeholder="Phone" appPhoneMask formControlName="phone" maxlength="12"
              data-test="phone-field" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="email" value="{{ email }}" disabled="true" readonly />
          </mat-form-field>
        </div>
        <div fxFlex="10" class="personal-info-title emergency-contact">
          <div class="emergency-text">
            Emergency Contact(s)
          </div>
          <div class="add-btn" *ngIf="emergencyContacts.length < 2" (click)="addEmergencyContact()">
            <mat-icon class="add-emergency" svgIcon="add-plus-icon"></mat-icon>
          </div>
        </div>
        <div formArrayName="emergencyContacts">
          <div *ngFor="let contact of emergencyContacts.controls; let i = index">
            <div class="emergency-contact-title">Emergency Contact {{ i + 1 }}</div>
            <div class="padding-left-sm padding-right-sm margin-bottom-sm"><mat-divider></mat-divider></div>
            <div [formGroupName]="i" fxflex="30" fxLayout="column" class="contact-form-field">
              <mat-form-field class="full-width">
                <mat-label>Name *</mat-label>
                <input matInput autocomplete="off" placeholder="Name" formControlName="emergencyContactName"
                  data-test="name-form-field" />
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Relation *</mat-label>
                <input matInput autocomplete="off" placeholder="Relation" formControlName="emergencyContactRelation"
                  data-test="relation-form-field" />
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Phone *</mat-label>
                <input matInput autocomplete="off" placeholder="Phone" appPhoneMask
                  formControlName="emergencyContactPhone" maxlength="12" data-test="phone-field" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>