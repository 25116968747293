import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs, { Dayjs } from 'dayjs';
import { Duty, OnHourDuty } from '../../shared/models/on-hour-duty.model';
import { DriverQualificationService } from '../../driver-qualification-files/service/driver-qualification.service';
import { catchError, finalize, first } from 'rxjs/operators';
const CERTIFY_DATE_FORMAT = 'DD MMM YYYY hh mm A';
const CERTIFY_DATE_BACKEND_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
@Component({
  selector: 'app-on-duty-hour',
  templateUrl: './on-duty-hour.component.html',
  styleUrls: ['./on-duty-hour.component.scss']
})
export class OnDutyHourComponent implements OnInit {
  loading: boolean;
  hoursCombo = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'];
  form: FormGroup;
  todayDate = new Date();
  workHourHistory: Duty[] = [];
  errorMessage: string;
  constructor(
    private readonly location: Location,
    private readonly fb: FormBuilder,
    private readonly driverQualificationService: DriverQualificationService
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.loadOnDutyHours();
  }
  goBack(): void {
    this.location.back();
  }
  loadOnDutyHours(): void {
    this.loading = true;
    this.driverQualificationService
      .getOnDutyHours(this.driverQualificationService.dqfId)
      .pipe(
        first(),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(res => {
        if (res) {
          this.populateForm(res);
        }
      });
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    } else {
      const saveModel = this.prepareModel();

      if (saveModel) {
        this.loading = true;
        this.driverQualificationService
          .updateOnDutyHours(saveModel)
          .pipe(
            catchError(error => {
              this.loading = false;
              const errorMessage = error.message;
              this.errorMessage = errorMessage;
              console.error(errorMessage);
              return error;
            })
          )
          .subscribe(res => {
            this.loadOnDutyHours();
          });
      }
    }
  }
  private populateForm(model: OnHourDuty) {
    if (!model) {
      return;
    }
    if (model.workedHoursHistory.length > 0) {
      this.workHourHistory = [];
      this.workHourHistory = model.workedHoursHistory.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      for (let i = 0; i < this.workHourHistory.length; i++) {
        let name = this.getFormControlName(i);
        this.form.get(name).setValue(this.workHourHistory[i].hoursWorked);
      }
    }
    let formattedDate = dayjs(this.todayDate).format(CERTIFY_DATE_FORMAT);
    this.form.get('certifyCreatedAt').setValue(formattedDate);
    this.form.get('id').setValue(model.id);
  }
  private prepareModel(): OnHourDuty {
    const formattedDate = dayjs(this.todayDate).format(CERTIFY_DATE_BACKEND_FORMAT);

    const formValue = this.form.value;
    let workHistoryModel: OnHourDuty = {
      id: this.form.get('id').value,
      workedHoursHistory: JSON.parse(
        JSON.stringify(this.workHourHistory.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()))
      ),
      certifyCreatedAt: formattedDate
    };
    for (let i = 0; i < this.workHourHistory.length; i++) {
      let name = this.getFormControlName(i);
      workHistoryModel.workedHoursHistory[i].hoursWorked = this.form.get(name).value;
    }

    return workHistoryModel;
  }
  private createForm(): FormGroup {
    const formattedDate = dayjs(this.todayDate).format(CERTIFY_DATE_FORMAT);

    const form = this.fb.group({
      id: [''],
      hour1: ['', Validators.required],
      hour2: ['', Validators.required],
      hour3: ['', Validators.required],
      hour4: ['', Validators.required],
      hour5: ['', Validators.required],
      hour6: ['', Validators.required],
      hour7: ['', Validators.required],
      hour8: [''],
      hour9: [''],
      hour10: [''],
      hour11: [''],
      hour12: [''],
      hour13: [''],
      hour14: [''],
      certifyCreatedAt: [formattedDate, Validators.required]
    });

    if (this.workHourHistory.length > 7) {
      for (let i = 8; i <= this.workHourHistory.length; i++) {
        const controlName = `hour${i}`;
        if (form.get(controlName)) {
          form.get(controlName).setValidators(Validators.required);
          form.get(controlName).updateValueAndValidity();
        }
      }
    }
    return form;
  }
  private getFormControlName(index) {
    return `hour${index + 1}`;
  }
}
