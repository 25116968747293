<div class="table-container-2">
    <h2 class="mat-h2">Authority Contacted</h2>
    <form [formGroup]="form" class=" mt-4">
      <div class="form-container">
        <div class="field-container">
          <div class="field-label">Organization:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input matInput placeholder="Organization" formControlName="organizationName" data-test="authorityName-form-field" />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Officer's Name:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input matInput placeholder="Officer's Name" formControlName="officerName" data-test="authorityName-form-field" />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Badge Number:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                placeholder="Badge Number"
                formControlName="badgeNumber"
                data-test="badgeNumber-form-field"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Report Number:</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                placeholder="Report Number"
                formControlName="reportNumber"
                data-test="reportNumber-form-field"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">Violations/Citations:</div>
          <div>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder="Violations"
                formControlName="violations"
                maxlength="200"
                data-test="violations-form-field"
                matTextareaAutosize
                matAutosizeMinRows="6"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  