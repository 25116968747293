export const ROUTE_SIGNUP = 'signup';
export const ROUTE_SIGNUP_COMPLETED = 'signup-completed';
export const ROUTE_SIGNUP_ERROR = 'signup-error';
export const ROUTE_ACCOUNT_LOCKED = 'account-locked';
export const ROUTE_SIGNUP_SUCCESS = 'signup-success';
export const ROUTE_SIGNIN = 'signin';
export const ROUTE_TERMS_CONDITIONS = 'terms-conditions';
export const ROUTE_PRIVACY_STATEMENT = 'privacy-statement';
export const ROUTE_IDENTITY = 'licenseStart';
export const ROUTE_IDENTITY_LAUNCH = 'licenseVerification';
export const ROUTE_ONBOARDING = 'onboarding';
export const ROUTE_LOGIN_ERROR = 'login-error';
export const ROUTE_PROFILE = 'profile';
export const ROUTE_DRIVER_CONSENT = 'share-profile-consent';
