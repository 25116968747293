import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-injured-details',
  templateUrl: './injured-details.component.html',
  styleUrls: ['./injured-details.component.scss']
})
export class InjuredDetailsComponent implements OnInit {
  form: FormGroup;
  displayedColumns: string[] = ['name', 'address', 'contactNumber', 'type', 'description'];
  dataSource = [];

  constructor(private fb: FormBuilder, private readonly cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.form = this.fb.group({
      injuredPersons: this.fb.array([]),
    });
    this.addRow(); // Add initial row

    this.dataSource = [...this.injuredPersons.controls];
  }

  get injuredPersons() {
    return this.form.get('injuredPersons') as FormArray;
  }

  addRow() {
    const personForm = this.fb.group({
      name: [''],
      address: [''],
      contactNumber: [''],
      type: [''],
      description: [''],
    });
    this.injuredPersons.push(personForm);
    this.dataSource = [...this.injuredPersons.controls];
    this.cdr.detectChanges();
  }


  removeRow(index: number) {
    this.injuredPersons.removeAt(index);
    this.updateDataSource();
  }

  private updateDataSource() {
    this.dataSource = [...this.injuredPersons.controls];
    this.cdr.detectChanges();
  }

}
