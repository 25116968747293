import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-witness-details',
  templateUrl: './witness-details.component.html',
  styleUrls: ['./witness-details.component.scss']
})
export class WitnessDetailsComponent implements OnInit {
  form: FormGroup;
  displayedColumns: string[] = ['name', 'address', 'contactNumber', 'type', 'description'];
  dataSource;

  constructor(private fb: FormBuilder, private readonly cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.form = this.fb.group({
      witnessDetails: this.fb.array([]),
    });
    this.addRow(); // Add initial row

    this.dataSource = [...this.witnessDetails.controls];
  }

  get witnessDetails() {
    return this.form.get('witnessDetails') as FormArray;
  }

  addRow() {
    const personForm = this.fb.group({
      witnessName: [''],
      witnessAddress: [''],
      witnessContactNumber: [''],
      witnessType: [''],
      witnessDescription: [''],
    });
    this.witnessDetails.push(personForm);
    this.dataSource = [...this.witnessDetails.controls];
    this.cdr.detectChanges();
  }

  removeRow(index: number) {
    this.witnessDetails.removeAt(index);
    this.updateDataSource();
  }

  private updateDataSource() {
    this.dataSource = [...this.witnessDetails.controls];
    this.cdr.detectChanges();
  }
}
