<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <app-loading-gif *ngIf="!isContentReady"></app-loading-gif>
  <div *ngIf="isContentReady">
    <div *ngIf="!readOnly">
      <div class="header-navigation-back ">
        <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
        <span fxFlex class="title">Shipment Details</span>
      </div>
      <div fxLayout="column" class="margin-bottom-md header">
        <div fxLayout="column" class="section margin-top-md">
          <div fxLayout class="shipment-meta-container">
            <div class="shipment-id-container">
              <div class="shipment-id">{{ shipment.shipmentID || '-' }}</div>
            </div>
            <div fxFlex="40" class="shipment-status-container">
              <div [ngClass]="[getShipmentStatusClass(), 'status-tag']">
                {{ shipment?.status === 'out for delivery' ? 'In Transit' : (shipment?.status | titlecase) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="shipment-stops-container margin-top-sm">
      <div class="stops">Stops ({{ this.statusComplete }}/{{ stops?.length || '0' }})</div>
    </div>
    <div *ngFor="let stop of stops; let i = index" (click)="toggleDetailSection(i, stop.id)" class="margin-top-md">
      <div [id]="'stop-details-' + selectedStop?.toString()">
        <div class="shipment-stops-container" data-test="shipment-stops-cotainer">
          <div class="shipment-stop-id-container">
            <div class="stops-id">
              <strong>{{ stop?.setOrder ? stop?.setOrder.toString().padStart(2, '0') : '-' }}</strong>
            </div>
          </div>
          <div fxLayout="column" class="stops-detail-box margin-top-sm">
            <div fxLayout class="content">
              <div
                *ngIf="activeStop === i && stop.status !== STATUS_COMPLETE"
                fxFlex="3"
                class="left-piece-order-1"
              ></div>
              <div *ngIf="activeStop !== i && stop.status !== STATUS_COMPLETE" fxFlex="3" class="left-piece"></div>
              <div *ngIf="stop.status === STATUS_COMPLETE" fxFlex="3" class="left-piece-complete"></div>
              <div fxFlex="67" class="margin-left-sm column">
                <div class="stops-name">
                  <strong>
                    <p>{{ getStopName(stop?.stopType) || '-' }}</p>
                  </strong>
                </div>
                <div *ngIf="stop?.stopType !== 'borderCross'">
                  <strong>
                    <p class="stops-location">
                      {{ stop?.city || '-' }}, {{ stop?.state || '-' }}, {{ stop?.country || '-' }}
                    </p>
                  </strong>
                  <p class="stops-zipcode margin-bottom-md">
                    {{ stop?.streetAddress || '-' }}, {{ stop?.zipcode || '-' }}
                  </p>
                </div>
                <div *ngIf="stop?.stopType === 'borderCross'">
                  <strong>
                    <p class="stops-location margin-bottom-md">
                      {{ stop?.borderCrossingPort ? stop?.borderCrossingPort.replace(',', ', ') : '-' }}
                    </p>
                  </strong>
                  <p class="stops-zipcode margin-bottom-md">{{ stop?.entryNumber || '-' }}</p>
                </div>
              </div>
              <div fxFlex="30" class="column right-column">
                <div class="date-time-container">
                  <p class="date">
                    <strong>{{ stop?.convertedDate || '-' }}</strong>
                  </p>
                  <p class="time margin-top-sm">{{ stop?.convertedTime || '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" *ngIf="selectedStopIndices.includes(i)" (click)="$event.stopPropagation()">
          <div fxLayout class="stop-details-container">
            <div fxFlex="0.5" class="left-line"></div>
            <div fxFlex="99.5">
              <div fxLayout class="detail-row margin-left-md">
                <div fxFlex="33" fxLayout class="margin-right-sm">
                  <div fxFlex="35" class="margin-right-sm">
                    <mat-icon svgIcon="truck" class="text-align-center"></mat-icon>&nbsp;
                  </div>
                  <div>
                    <div class="detail-title">Unit</div>
                    <div class="detail-value">
                      {{ shipment?.unitNumber || '-' }}
                    </div>
                  </div>
                </div>
                <div fxFlex="33" fxLayout>
                  <div fxFlex="35" class="margin-right-sm">
                    <mat-icon svgIcon="trailer" class="grey-icon text-align-center"></mat-icon>&nbsp;
                  </div>
                  <div>
                    <div class="detail-title">Trailer</div>
                    <div class="detail-value">{{ shipment?.trailerNumber || '-' }}</div>
                  </div>
                </div>
              </div>
              <div class="instruction-box">
                <p class="instructions">
                  <strong class="business-value">Instructions:&nbsp;&nbsp;</strong> {{ stop?.instructions || '-' }}
                </p>
              </div>
              <div class="border-cross-type" fxLayout>
                <div
                  fxflex="46"
                  *ngIf="stop?.stopType === 'borderCross'"
                  class="detail-row margin-bottom-sm margin-top-md"
                >
                  <div class="business-title wrap-text">Border Crossing Port</div>
                  <div class="business-value wrap-text">
                    {{ stop?.borderCrossingPort ? stop?.borderCrossingPort.replace(',', ', ') : '-' }}
                  </div>
                </div>
                <div fxFlex="48"></div>
              </div>
              <div fxLayout="column">
                <div *ngIf="stop?.stopType !== 'borderCross'" fxLayout class="detail-row margin-top-md">
                  <div fxFlex="8"></div>
                  <div fxFlex="46">
                    <div class="margin-bottom-sm">
                      <div class="business-title wrap-text">Business Name</div>
                      <div class="business-value wrap-text">{{ stop?.businessName || '-' }}</div>
                    </div>
                    <div class="margin-top-md margin-right-sm">
                      <div class="business-title wrap-text margin-top-sm">Contact Email</div>
                      <div class="business-value wrap-text">{{ stop?.contactEmail || '-' }}</div>
                    </div>
                  </div>
                  <div fxFlex="46">
                    <div class="margin-bottom-sm margin-left-sm">
                      <div class="business-title wrap-text">Contact Name</div>
                      <div class="business-value wrap-text">{{ stop?.contactName || '-' }}</div>
                    </div>
                    <div class="margin-top-md margin-left-sm">
                      <div class="business-title wrap-text margin-top-sm">Contact Phone</div>
                      <div class="business-value wrap-text">{{ stop?.contactPhone || '-' }}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="stop?.stopType === 'borderCross'" fxLayout class="detail-row margin-top-md">
                  <div fxFlex="8"></div>
                  <div fxFlex="46" *ngIf="stop?.aceEManifestNumber || stop?.aciEManifestNumber">
                    <div class="margin-right-sm margin-left-md">
                      <div *ngIf="stop?.aceEManifestNumber">
                        <div class="business-title wrap-text">ACE eManiefest Number</div>
                        <div class="business-value wrap-text">{{ stop?.aceEManifestNumber || '-' }}</div>
                      </div>
                      <div *ngIf="stop?.aciEManifestNumber">
                        <div class="business-title wrap-text">ACI eManiefest Number</div>
                        <div class="business-value wrap-text">{{ stop?.aciEManifestNumber || '-' }}</div>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="46">
                    <div class="margin-left-md">
                      <div class="business-title wrap-text">Entry Number</div>
                      <div class="business-value wrap-text">{{ stop?.entryNumber || '-' }}</div>
                    </div>
                  </div>
                  <div fxFlex="46" *ngIf="!(stop?.aceEManifestNumber || stop?.aciEManifestNumber)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-row">
            <div *ngIf="!(stop.stopType === 'borderCross') && !readOnly">
              <button
                *ngIf="
                  !(stop.stopType === PICKUP_LOAD || stop.stopType === DELIVER_LOAD) &&
                  showUploadButton(stop) &&
                  disableHookTrailerUpload(stop) &&
                  disableDropTrailerUpload(stop)
                "
                class="full-width pending"
                mat-raised-button
                type="submit"
                (click)="openActionSheet(stop.id, stop.stopType)"
                [disabled]="isButtonDisabled(i)"
                data-test="upload-document-button"
              >
                Upload {{ stop?.documentUploadName }}
              </button>
              <button
                *ngIf="
                  ((stop.stopType === PICKUP_LOAD && stop?.document?.type !== 'BillOfLading') ||
                    (stop.stopType === DELIVER_LOAD && stop?.document?.type !== 'ProofOfDelivery')) &&
                  shouldDisplayButton(stop)
                "
                class="full-width pending"
                mat-raised-button
                type="submit"
                (click)="openActionSheet(stop.id, stop.stopType)"
                [disabled]="isButtonDisabled(i)"
                data-test="upload-document-button"
              >
                Upload {{ stop?.documentUploadName }}
              </button>
            </div>
            <div *ngIf="stop.stopType === 'borderCross' && !readOnly">
              <button
                *ngIf="stop?.document?.type !== 'ACIeManifest' && stop?.document?.role !== 'Dispatcher'"
                class="full-width pending"
                mat-raised-button
                type="submit"
                (click)="openActionSheet(stop.id, stop.stopType, 'ACIeManifest')"
                [disabled]="isButtonDisabled(i)"
                data-test="upload-document-button"
              >
                Upload Stamped ACI eManifest
              </button>
              <button
                *ngIf="stop?.document?.type !== 'ACEeManifest' && stop?.document?.role !== 'Dispatcher'"
                class="full-width pending margin-top-sm"
                mat-raised-button
                type="submit"
                (click)="openActionSheet(stop.id, stop.stopType, 'ACEeManifest')"
                [disabled]="isButtonDisabled(i)"
                data-test="upload-document-button"
              >
                Upload Stamped ACE eManifest
              </button>
              <button
                *ngIf="stop?.document?.type === 'ACIeManifest' && stop?.document?.role !== 'Dispatcher'"
                class="full-width view-document"
                mat-raised-button
                type="submit"
                (click)="viewUploadedDocument(stop?.document.id)"
                data-test="view-document-button"
              >
                View Stamped ACI eManifest
              </button>
              <button
                *ngIf="stop?.document?.type === 'ACEeManifest' && stop?.document?.role !== 'Dispatcher'"
                class="full-width view-document"
                mat-raised-button
                type="submit"
                (click)="viewUploadedDocument(stop?.document.id)"
                data-test="view-document-button"
              >
                View Stamped ACE eManifest
              </button>
              <button
                class="full-width complete"
                mat-raised-button
                type="submit"
                *ngIf="
                  (stop?.document?.type === 'ACEeManifest' || stop?.document?.type === 'ACIeManifest') &&
                  stop.status !== STATUS_COMPLETE
                "
                (click)="changeStopStatus(stop, STATUS_COMPLETE)"
                data-test="stop-complete-button"
              >
                Complete
              </button>
            </div>
            <div *ngIf="stop.stopType !== 'borderCross' && !readOnly">
              <button
                class="full-width complete"
                mat-raised-button
                type="submit"
                *ngIf="
                  !stop?.document &&
                  (stop.stopType === DELIVER_LOAD || stop.stopType === PICKUP_LOAD) &&
                  stop.status === STATUS_PENDING
                "
                (click)="changeStopStatus(stop, getButtonName(stop?.stopType))"
                [disabled]="isButtonDisabled(i)"
                data-test="loading-unloading-button"
              >
                {{ getButtonName(stop?.stopType) | titlecase }}
              </button>
              <button
                *ngIf="stop?.document"
                class="full-width view-document"
                mat-raised-button
                type="submit"
                (click)="viewUploadedDocument(stop?.document.id)"
                data-test="view-document-button"
              >
                View {{ stop?.documentDisplayName }}
              </button>
              <button
                class="full-width complete"
                mat-raised-button
                type="submit"
                *ngIf="
                  (((stop?.document && stop?.document?.status === 'uploaded') ||
                    this.disableDocumentUpload.includes(stop.stopType) ||
                    !disableHookTrailerUpload(stop) ||
                    !disableDropTrailerUpload(stop)) &&
                    stop.status !== STATUS_COMPLETE) ||
                  (stop?.document &&
                    stop?.document?.status === 'uploaded' &&
                    ((stop.stopType === PICKUP_LOAD && stop?.document?.type === 'BillOfLading') ||
                      (stop.stopType === DELIVER_LOAD && stop?.document?.type === 'ProofOfDelivery')) &&
                    stop.status !== STATUS_COMPLETE)
                "
                (click)="changeStopStatus(stop, STATUS_COMPLETE)"
                [disabled]="isButtonDisabled(i)"
                data-test="stop-complete-button"
              >
                Complete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
