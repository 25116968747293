import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-authority-contacted',
  templateUrl: './authority-contacted.component.html',
  styleUrls: ['./authority-contacted.component.scss']
})
export class AuthorityContactedComponent implements OnInit {
  form: FormGroup;
  
  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      organizationName: [''],
      officerName: [''],
      badgeNumber: [''],
      reportNumber: [''],
      violations: [''],
    });
  }
}
