import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-accident-details',
  templateUrl: './accident-details.component.html',
  styleUrls: ['./accident-details.component.scss']
})
export class AccidentDetailsComponent implements OnInit {
  form: FormGroup;

  constructor(private readonly fb: FormBuilder) {}
  ngOnInit(): void {
    this.form = this.createForm();
  }

  createForm(): FormGroup {
    return this.fb.group({
      accidentDate: [''],
      accidentTime: [''],
      accidentLocation: [''],
      description: [''],
      conditions: [''],
      weatherClear: [''],
      weatherCloudy: [''],
      weatherFog: [''],
      weatherSleet: [''],
      weatherSnow: [''],
      weatherRain: [''],
    });
  }

}
