import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';

import { RegistrationComponent } from './registration/registration.component';
import { WebcamSnapshotComponent } from 'src/app/registration/webcam-snapshot/webcam-snapshot.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { KYCVerificationService } from 'src/app/registration/shared/kyc-verification.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { EmploymentState } from 'src/app/registration/state/employment.state';
import { RegistrationRouterState } from 'src/app/registration/state/registration-router.state';
import { EmploymentFormComponent } from './employment-form/employment-form.component';
import { EmploymentViewComponent } from './employment-view/employment-view.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { EmploymentHistoryComponent } from './employment-history/employment-history.component';
import { EducationComponent } from './education/education.component';
import { DrivingExperienceComponent } from './driving-experience/driving-experience.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { TruckTypeComponent } from './truck-type/truck-type.component';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProfileModule } from '../profile/profile.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { DriverAbstractComponent } from './driver-abstract/driver-abstract.component';
import { DriveTestComponent } from './drive-test/drive-test.component';
import { MedicalExaminationComponent } from './medical-examination/medical-examination.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CompletionMeterModule } from '../shared/completion-meter/completion-meter.module';
import { ResumeComponent } from './resume/resume.component';
import { PhoneMaskModule } from '../phone-mask/phone-mask.module';
import { LoadingGifModule } from '../shared/loading-gif/loading-gif.module';
import { ActionsModule } from '../shared/actions/actions.module';
import { MatMenuModule } from '@angular/material/menu';
import { ResidenceHistoryComponent } from './residence-history/residence-history.component';
import { AccidentRecordViewComponent } from './accident-view/accident-view.component';
import { AccidentRecordsComponent } from './accident-records/accident-records.component';
import { AdditionalDocumentsComponent } from './additional-documents/additional-documents.component';
import { AdditionalDocumentViewComponent } from './additional-document-view/additional-document-view.component';
import { ProfileLicenseInformationComponent } from '../profile/profile-license-information/profile-license-information.component';
import { TrafficConvictionsComponent } from './traffic-convictions/traffic-convictions.component';
import { SinMaskModule } from '../sin-mask/sin-mask.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OnDutyHourComponent } from './on-duty-hour/on-duty-hour.component';
import { ProfileLoadingComponent } from '../profile/profile/profile-loading/profile-loading.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonAccidentTrafficRecordsComponent } from './skeleton-accident-traffic-records/skeleton-accident-traffic-records.component';
import { SkeletonEmpResidenceHistoryComponent } from './skeleton-emp-residence-history/skeleton-emp-residence-history.component';
import { EmploymentHistoryFormEmployedComponent } from './employment-history/employment-history-form-employed/employment-history-form-employed.component';
import { EmploymentHistoryFormUnemployedComponent } from './employment-history/employment-history-form-unemployed/employment-history-form-unemployed.component';
import { RequirementsCompleteActionComponent } from './requirements-complete-action/requirements-complete-action.component';
import { IncorporationComponent } from './incorporation/incorporation.component';
import { ComponentsModule } from '../shared/components/components.module';
import { AdditionalDocumentUploadComponent } from './additional-document-upload/additional-document-upload.component';
import { CustomFormValidatorsModule } from '../shared/custom-form-validators/custom-form-validators.module';
import { ResidenceHistoryFormComponent } from './residence-history/residence-history-form/residence-history-form.component';
import { ResidenceState } from 'src/app/registration/state/residence.state';
import { ProfileAccessConsentComponent } from '../profile/profile-access-consent/profile-access-consent';
@NgModule({
  declarations: [
    RegistrationComponent,
    WebcamSnapshotComponent,
    EmploymentFormComponent,
    EmploymentViewComponent,
    ProfileComponent,
    EmploymentHistoryComponent,
    EducationComponent,
    DrivingExperienceComponent,
    PreferencesComponent,
    ProfilePictureComponent,
    TruckTypeComponent,
    PersonalInformationComponent,
    DriverAbstractComponent,
    DriveTestComponent,
    MedicalExaminationComponent,
    InsuranceComponent,
    ResumeComponent,
    ResidenceHistoryComponent,
    ProfileLicenseInformationComponent,
    TrafficConvictionsComponent,
    AccidentRecordsComponent,
    AccidentRecordViewComponent,
    AccidentRecordsComponent,
    AdditionalDocumentsComponent,
    AdditionalDocumentViewComponent,
    OnDutyHourComponent,
    ProfileLoadingComponent,
    SkeletonAccidentTrafficRecordsComponent,
    SkeletonEmpResidenceHistoryComponent,
    EmploymentHistoryFormEmployedComponent,
    EmploymentHistoryFormUnemployedComponent,
    RequirementsCompleteActionComponent,
    IncorporationComponent,
    AdditionalDocumentUploadComponent,
    ResidenceHistoryFormComponent,
    ProfileAccessConsentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WebcamModule,
    MaterialModule,
    NgxsModule.forFeature([RegistrationState, RegistrationRouterState, EmploymentState, ResidenceState]),
    ImageCropperModule,
    ProfileModule,
    MatFormFieldModule,
    CompletionMeterModule,
    PhoneMaskModule,
    LoadingGifModule,
    ActionsModule,
    MatMenuModule,
    SinMaskModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule.forRoot(),
    ComponentsModule,
    CustomFormValidatorsModule
  ],
  providers: [KYCVerificationService, RegistrationService, DriverApiService, DatePipe, CommonService]
})
export class RegistrationModule {}

export { RegistrationComponent } from './registration/registration.component';
