<div fxLayoutAlign="start" fxLayout="column" class="page font-family">
  <div class="header-navigation-back-profile-consent">
    <span class="title">Driver Consent Form</span>
  </div>
  <app-loading-gif *ngIf="loading"></app-loading-gif>
  <div class="content" *ngIf="!loading && !consentProcessed">
    <div class="consent-success" *ngIf="consentData">
      <div class="consent-desciption">
        <p class="consent-desc-text">
          I hereby provide my consent to share my profile information with
          <b>{{ consentData.requesterBehalfOfCompanyName }}</b>
        </p>
      </div>
      <div fxLayout="column" fxLayoutAlign="center stretch" class="consent-buttons">
        <button
          mat-button
          (click)="onApproveConsent()"
          data-test="approve-consent-button"
          class="full-width-button btn-approve"
        >
          APPROVE
        </button>
        <button
          mat-button
          (click)="rejectConsent()"
          data-test="reject-consent-button"
          class="full-width-button btn-reject"
        >
          REJECT
        </button>
      </div>
    </div>
    <!-- Show consent failure message if consentData is not available -->
    <div class="consent-failure center" *ngIf="!consentData" fxLayout="column" fxLayoutAlign="center">
      <div class="icon-alert">
        <mat-icon svgIcon="alert"></mat-icon>
      </div>
      <p class="error-message">
        {{ consentStatusMessage || genericErrorMessage }}
      </p>
    </div>
  </div>
  <!-- Show success or failure message after processing consent -->
  <div
    class="consent-processed-message center"
    *ngIf="!loading && consentProcessed"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <div class="icon-alert">
      <mat-icon svgIcon="tick-blue"></mat-icon>
    </div>
    <p class="success-message center">{{ consentStatusMessage }}</p>
  </div>
</div>
