import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap, map, mergeMap, switchMap, first, catchError } from 'rxjs/operators';
import * as mime from 'mime';
import { HttpClient } from '@angular/common/http';
import { RegistrationState, DriverStateModel } from 'src/app/registration/state/registration.state';
import { EmploymentState } from 'src/app/registration/state/employment.state';
import {
  SetEducation,
  SetDrivingExperience,
  SetPreferences,
  SetProfilePicture,
  SetDriver,
  ClearRegistration,
  SetNotification,
  ClearDriver,
  UpdateDriver,
  SetDispatcherContact,
  SetWorkPlaceSafety,
  SetLicense,
  ClearLicense
} from 'src/app/registration/state/registration.actions';
import { SetEmploymentHistory } from 'src/app/registration/state/employment.actions';
import { Navigate } from '../state/registration-router.state';
import { Employment, Unemployment } from 'src/app/shared/models/employment.model';
import { Education } from 'src/app/shared/models/education.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { DriverApiService, IDENTITY_REVIEW } from 'src/app/shared/services/driver-api.service';
import { Driver } from 'src/app/shared/models/driver.model';
import { ROUTE_DRIVER_DASHBOARD, ROUTE_REGISTRATION, ROUTE_PROFILE } from 'src/app/shared/routes';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { ProfilePictureReponse } from 'src/app/shared/models/profie-picture-response.model';
import { ProfilePictureRequest } from 'src/app/shared/models/profile-picture-request.model';
import { Response } from '../../shared/services/response';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { Token } from 'src/app/shared/models/token.model';
import { SendDocumentResponse } from 'src/app/shared/documents-data/models/send-document-response';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { MilesDriven } from './models/miles-driven.model';
import { ResidenceAddress } from './models/residence-adress.model';
import { MainTrafficConviction, TrafficConviction } from 'src/app/shared/models/traffic-convictions';
import { AccidentRecord } from 'src/app/shared/models/accident-record.model';
import { License } from 'src/app/shared/models/license.model';
import { DownloadDocument } from 'src/app/shared/models/download-document.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { DispatcherContact } from 'src/app/shared/models/dispatcher-contact.model';
import { DRIVER_TYPE_MAP } from 'src/app/driver-qualification-files/constants';
import { WorkPlaceSafety } from 'src/app/shared/models/workplacesafety.model';
import { ResidenceState } from 'src/app/registration/state/residence.state';
import { SetResidenceHistory } from 'src/app/registration/state/residence.actions';
import { DriverConsent } from './models/driver-consent.model';

const PATH_PROFILE = 'profile/';
const PATH_IDENTITYCHECK = 'identityCheck/';
const PATH_EMPLOYMENT_HISTORY = 'employmentHistory';
const PATH_EDUCATION = 'education';
const PATH_DRIVING_EXPERIENCE = 'drivingExperience';
const PATH_PREFERENCES = 'preferences';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_PERSONAL_INFORMATION = 'personalInfo/';
const PATH_DRIVER_ABSTRACT = 'driverAbstract';
const PATH_MEDICAL_EXAMINATION = 'medicalExamination';
const PATH_INSURANCE = 'insurance';
const PATH_INCORPORATION = 'incorporation';
const PATH_DRIVE_TEST = 'driveTest';
const PATH_RESUME = 'resume';
const PATH_ACCIDENT_RECORDS = 'accidentRecords';
const PATH_TRAFFIC_CONVICTIONS = 'trafficConvictions';
const PATH_LICENSE = 'license';
const PATH_RESIDENCE_HISTORY = 'residenceHistory';
const KEY_DRIVER = 'driver';
const KEY_DISPATCHER_CONTACT = 'dispatcherContact';
const KEY_LICENSE = 'license';
const PATH_LICENSE_STATUS = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseStatus';
const PATH_DRIVER_LICENSE_START = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseStart';
const PATH_DRIVER_LICENSE_VERIFICATION = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseVerification';
const PATH_DOCUMENTS = 'documents';
const PATH_UPLOADS = 'uploads';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  employmentHistoryList: any;

  constructor(
    private readonly route: Router,
    private readonly store: Store,
    private readonly driverApi: DriverApiService,
    private readonly storageService: StorageService,
    private readonly logger: LoggerService,
    private readonly fileDataService: FileDataService,
    private readonly http: HttpClient
  ) {
    this.store.dispatch([]);

    const driver = this.storageService.find(KEY_DRIVER) as Driver;
    if (driver) {
      const driverStateModel = this.createDriverStateModel(driver);
      this.store.dispatch([new SetDriver(driverStateModel)]);
    }
  }

  clearRegistration(): void {
    this.store.dispatch([new ClearRegistration()]);
  }

  loadDriver(isLoadingFalse?: boolean, version?: number, includeStats?: boolean): Observable<any> {
    return this.driverApi.getDriver(undefined, isLoadingFalse, version, includeStats).pipe(
      first(),
      map((response: any) => response.data),
      tap((driver: Driver) => {
        if (!version) {
          this.storageService.store(KEY_DRIVER, driver);
          const driverStateModel = this.createDriverStateModel(driver);
          this.store.dispatch([new SetDriver(driverStateModel)]);
          this.store.dispatch([new SetNotification(driver.notification)]);
          this.store.dispatch([new SetEducation(driver.education)]);
          this.store.dispatch([new SetDrivingExperience(driver.experience)]);
          this.store.dispatch([new SetPreferences(driver.preference)]);
          this.store.dispatch([new SetWorkPlaceSafety(driver.workplaceSafety)]);
        }
      }),
      catchError(err => {
        throw err;
      })
    );
  }
  loadDriverWithDecryptedSin(revelFields: string): Observable<any> {
    return this.driverApi.getDriver(revelFields).pipe(map((response: any) => response.data as Driver));
  }

  getDriverIdentityStatus(): string {
    const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);
    if (driverStateModel) {
      return driverStateModel.identityStatus;
    }
  }
  getDriverStage(): string {
    const driver = this.store.selectSnapshot(RegistrationState.driver);
    if (driver && driver?.stage) {
      return driver?.stage;
    }
  }

  getDriver(): Driver {
    const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);
    if (!driverStateModel || !driverStateModel.id) {
      return;
    }

    const driver = this.createDriver(driverStateModel);
    return driver;
  }

  getDriverId(): Observable<Response> {
    return this.driverApi.getDriverId().pipe(
      first(),
      map((response: Response) => response.data),
      catchError(err => {
        err = { ...err, type: 'session' };
        throw err;
      })
    );
  }

  loadDispatcherContact(): Observable<any> {
    return this.driverApi.getDispatcherContact().pipe(
      first(),
      map((response: any) => {
        if (response.data) {
          let dispatcherData: DispatcherContact = response.data[0];
          this.storageService.store(KEY_DISPATCHER_CONTACT, dispatcherData);
          this.store.dispatch([new SetDispatcherContact(dispatcherData)]);
        }
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getDispatcherContact(): DispatcherContact {
    return this.store.selectSnapshot(RegistrationState.dispatcherContact);
  }

  fetchCompanyData(recaptchaToken: string, inviteCode: string): Observable<Response> {
    const headerOptions = { 'x-recaptcha-token': recaptchaToken };
    const queryString = `?code=${inviteCode}`;
    return this.driverApi.getCarrierCompanyDetailsFromInviteCode(queryString, headerOptions);
  }

  clearDriver(): void {
    this.store.dispatch([new ClearDriver()]);
    this.storageService.remove(KEY_DRIVER);
    this.store.dispatch([new ClearLicense()]);
    this.storageService.remove(KEY_LICENSE);
  }
  loadProfilePicture(): Observable<ProfilePicture> {
    return this.driverApi.getProfilePicture().pipe(
      map((response: any) => {
        return response?.data ? (response.data as ProfilePicture) : undefined;
      }),
      mergeMap((picture: ProfilePicture) => {
        return this.setProfilePicture(picture);
      })
    );
  }
  loadProfileImageThumbnail(isLoadingFalse?: boolean): Observable<ProfilePicture> {
    return this.driverApi.getProfilePicture(isLoadingFalse).pipe(
      map((response: any) => {
        return response?.data ? (response.data as ProfilePicture) : undefined;
      })
    );
  }
  getBerbixClientToken(codeChallenge: string): Observable<Token> {
    return this.driverApi.getBerbixClientToken(codeChallenge).pipe(map((response: Response) => response.data as Token));
  }

  updateIdentityStatus(transactionId: string, codeVerifier: string): Observable<Response> {
    return this.driverApi
      .updateIdentityStatus(IDENTITY_REVIEW, transactionId, codeVerifier)
      .pipe(map((response: Response) => response.data));
  }

  setProfilePicture(profilePicture: ProfilePicture): Observable<ProfilePicture> {
    return this.store.dispatch([new SetProfilePicture(profilePicture)]);
  }

  saveProfilePicture(profilePicture: any, extension: string): Observable<Response> {
    return this.createProfilePicture(extension).pipe(
      switchMap((profilePictureReponse: ProfilePictureReponse) => {
        const url = profilePictureReponse.url;
        const newFileName = profilePictureReponse.file;

        const file = profilePicture;
        const renamedFile: File = new File([file], newFileName, { type: file.type });
        const contentType = mime.getType(extension);

        return this.fileDataService.uploadFile(renamedFile, url, contentType);
      }),
      mergeMap(() => this.store.dispatch([new SetProfilePicture(profilePicture as ProfilePicture)]))
    );
  }

  saveEmployment(model: Employment | Unemployment): Observable<any> {
    return this.driverApi.createEmployment(model);
  }

  updateEmployment(model: Employment | Unemployment, id: string): Observable<any> {
    return this.driverApi.updateEmployment(model, id);
  }

  deleteEmployment(model: Employment | Unemployment): Observable<any> {
    return this.driverApi.deleteEmployment(model.id);
  }

  saveEducation(model: Education): Observable<any> {
    return this.driverApi.createEducation(model).pipe(
      map((response: any) => {
        return response.data as Education;
      }),
      mergeMap((education: Education) => {
        return this.setEducation(education);
      })
    );
  }

  updateEducation(model: Education): Observable<any> {
    return this.driverApi.updateEducation(model).pipe(
      map((response: any) => {
        return response.data as Education;
      }),
      mergeMap((education: Education) => {
        return this.setEducation(education);
      })
    );
  }

  saveIncorporation(model: WorkPlaceSafety): Observable<any> {
    return this.driverApi.createIncorporation(model).pipe(
      map((response: any) => {
        return response.data as WorkPlaceSafety;
      }),
      mergeMap((incorporation: WorkPlaceSafety) => {
        return this.setWorkPlaceSafety(incorporation);
      })
    );
  }

  updateIncorporation(model: WorkPlaceSafety, id: string): Observable<any> {
    return this.driverApi.updateIncorporation(model, id).pipe(
      map((response: any) => {
        return response.data as WorkPlaceSafety;
      }),
      mergeMap((incorporation: WorkPlaceSafety) => {
        return this.setWorkPlaceSafety(incorporation);
      })
    );
  }

  updateDriver(driver: Driver): Observable<any> {
    return this.driverApi.updateDriver(driver).pipe(
      map((response: any) => {
        return response.data as Driver;
      }),
      tap((driver: Driver) => {
        const model = this.createDriverStateModel(driver);
        return this.store.dispatch([new UpdateDriver(model)]);
      })
    );
  }

  selectDrivingExperience(): Observable<DrivingExperience> {
    return this.store.select(RegistrationState.drivingExperience);
  }

  getMilesDriven(): Observable<MilesDriven[]> {
    return this.driverApi.getMilesDrivenOptions().pipe(
      map((response: any) => {
        return response.data.milesDriven as MilesDriven[];
      })
    );
  }

  getPrefilledBerbixData(id: string): Observable<Driver> {
    return this.driverApi.getPrefilledBerbixData(id).pipe(
      map((response: any) => {
        return response.data as Driver;
      })
    );
  }

  saveDrivingExperience(model: DrivingExperience): Observable<any> {
    return this.driverApi.createDrivingExperience(model);
  }

  updateDrivingExperience(model: DrivingExperience): Observable<any> {
    return this.driverApi.updateDrivingExperience(model);
  }

  getDrivingExperience(version?: number): Observable<any[]> {
    return this.driverApi.getDrivingExperience(version).pipe(map((response: Response) => response.data ?? []));
  }

  saveDocument(model: DocumentRequest, documentFile: any, isLoadingFalse?: boolean): Observable<Response> {
    return this.driverApi.sendDocument(model, isLoadingFalse).pipe(
      switchMap((sendDocumentResponse: SendDocumentResponse) => {
        const url = sendDocumentResponse.url;
        const newFileName = sendDocumentResponse.file;

        const file = documentFile;
        const renamedFile: File = new File([file], newFileName, { type: file.type });
        const contentType = mime.getType(model.extension);

        return this.fileDataService.uploadFile(renamedFile, url, contentType, isLoadingFalse);
      })
    );
  }

  downloadDocument(documentId): Observable<any> {
    return this.driverApi.downloadDocument(documentId).pipe(map(document => document.data as DownloadDocument));
  }
  deleteDocument(documentId): Observable<any> {
    return this.driverApi.deleteDocument(documentId).pipe(map(document => document.data as DownloadDocument));
  }
  selectPreferences(): Observable<Preferences> {
    return this.store.select(RegistrationState.preferences);
  }

  savePreferences(model: Preferences): Observable<any> {
    return this.driverApi.createPreferences(model).pipe(
      map((response: any) => {
        return response.data as Preferences;
      })
    );
  }

  getPreferences(): Observable<any> {
    return this.driverApi.getPreferences().pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updatePreferences(model: Preferences): Observable<any> {
    return this.driverApi.updatePreferences(model).pipe(
      map((response: any) => {
        return response.data as Preferences;
      })
    );
  }

  saveAccidentRecord(model: AccidentRecord): Observable<AccidentRecord> {
    return this.driverApi.createAccidentRecord(model);
  }

  updateAccidentRecord(model: AccidentRecord, id: string): Observable<any> {
    return this.driverApi.updateAccidentRecord(model, id);
  }

  deleteAccidentRecord(id: string): Observable<any> {
    return this.driverApi.deleteAccidentRecord(id);
  }

  getAccidentRecords(version?: number): Observable<AccidentRecord[]> {
    return this.driverApi.getAccidentRecords(version).pipe(map((response: Response) => response.data ?? []));
  }

  selectEducation(): Observable<Education> {
    return this.store.select(RegistrationState.education);
  }

  setEducation(education: Education): Observable<any> {
    return this.store.dispatch([new SetEducation(education)]);
  }

  selectWorkPlaceSafety(): Observable<WorkPlaceSafety> {
    return this.store.select(RegistrationState.workplaceSafety);
  }

  setWorkPlaceSafety(incorporation: WorkPlaceSafety): Observable<any> {
    return this.store.dispatch([new SetWorkPlaceSafety(incorporation)]);
  }

  setDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    return this.store.dispatch([new SetDrivingExperience(drivingExperience)]);
  }

  setPreferences(preferences: Preferences): Observable<any> {
    return this.store.dispatch([new SetPreferences(preferences)]);
  }

  navigateToProfile() {
    this.store.dispatch([new Navigate(ROUTE_PROFILE)]);
  }

  navigateToIdentityStart() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_START)]);
  }

  navigateToIdentityLaunch() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_VERIFICATION)]);
  }

  navigateToDriverLicenseStatus() {
    this.store.dispatch([new Navigate(PATH_LICENSE_STATUS)]);
  }

  navigateToEmploymentHistory(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_EMPLOYMENT_HISTORY, activatedRoute)]);
  }

  navigateToEducation(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_EDUCATION, activatedRoute)]);
  }
  navigateToDocuments(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DOCUMENTS, activatedRoute)]);
  }
  navigateToDocumentType(type: string, activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(`${PATH_DOCUMENTS}/${type}`, activatedRoute)]);
  }
  navigateToDrivingExperience(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVING_EXPERIENCE, activatedRoute)]);
  }

  navigateToPreferences(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_PREFERENCES, activatedRoute)]);
  }

  navigateToProfilePicture(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(`${PATH_PROFILE_PICTURE}`, activatedRoute)]);
  }
  navigateToPersonalInformation(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(`${PATH_PERSONAL_INFORMATION}`, activatedRoute)]);
  }

  navigateToDriverDashboard() {
    this.store.dispatch([new Navigate(ROUTE_DRIVER_DASHBOARD)]);
  }
  navigateToDriverAbstract(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVER_ABSTRACT, activatedRoute)]);
  }
  navigateToInsurance(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_INSURANCE, activatedRoute)]);
  }
  navigateToIncorporation(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_INCORPORATION, activatedRoute)]);
  }
  navigateToMedicalExamination(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_MEDICAL_EXAMINATION, activatedRoute)]);
  }
  navigateToDriveTest(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVE_TEST, activatedRoute)]);
  }
  navigateToResume(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_RESUME, activatedRoute)]);
  }
  navigateToAccidentRecords(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_ACCIDENT_RECORDS, activatedRoute)]);
  }
  onNavigateToTrafficConvictions(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_TRAFFIC_CONVICTIONS, activatedRoute)]);
  }
  onNavigateToLicense(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_LICENSE, activatedRoute)]);
  }
  navigateToResidenceHistory(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_RESIDENCE_HISTORY, activatedRoute)]);
  }
  navigateToUploads(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_UPLOADS, activatedRoute)]);
  }

  checkInRegistration(): any {
    return this.route.url.indexOf(ROUTE_REGISTRATION) !== -1;
  }
  //residence history functions

  getAllAddress(version?: number): Observable<ResidenceAddress[]> {
    return this.driverApi.getAddresses(version).pipe(
      map(res => {
        const addresses = [...res.data] as ResidenceAddress[];
        this.store.dispatch([new SetResidenceHistory(addresses)]);
        return res.data as ResidenceAddress[];
      })
    );
  }
  getAddressesFromStore() {
    const addresses = this.store.selectSnapshot(ResidenceState.residenceHistory);
    return addresses;
  }

  // Traffic Convictions
  saveTrafficConviction(model: TrafficConviction | MainTrafficConviction) {
    return this.driverApi.createTrafficConvictions(model).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getTrafficConvictions(version?: number): Observable<any[]> {
    return this.driverApi.getTrafficConvictions(version).pipe(map((response: Response) => response.data ?? []));
  }

  updateTrafficConviction(model: TrafficConviction | MainTrafficConviction, convictionId: string) {
    return this.driverApi.updateTrafficConvictions(model, convictionId).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteTrafficConviction(convictionId: string): Observable<any> {
    return this.driverApi.deleteTrafficConvictions(convictionId);
  }
  //Residence History
  updateAddress(model, id: string): Observable<any> {
    return this.driverApi.updateAddress(model, id).pipe(
      map(response => {
        return response;
      })
    );
  }
  addAddress(model): Observable<any> {
    return this.driverApi.addAddress(model).pipe(
      map(response => {
        return response;
      })
    );
  }

  // employment History
  getEmploymentHistory(version?: number): Observable<any> {
    const employmentData = this.store.selectSnapshot(EmploymentState.employmentHistory);
    if (!version && employmentData?.length > 0) {
      return of(employmentData);
    }
    return this.driverApi.getEmploymentHistory(version).pipe(
      map((response: Response) => {
        this.employmentHistoryList = [...response.data];
        this.store.dispatch([new SetEmploymentHistory(response.data)]);
        return response.data;
      })
    );
  }

  updateEmploymentHistoryStore(newEmploymentHistory: any[]): void {
    const validRecords = newEmploymentHistory.filter(emp => emp?.id);
    this.store.dispatch([new SetEmploymentHistory(validRecords)]);
  }
  //License
  getLicense(isLoadingFalse?: boolean, version?: number, licenseImageType?: string): Observable<License> {
    return this.driverApi.getLicense(isLoadingFalse, version, licenseImageType).pipe(
      map((response: Response) => {
        const license = response.data as License | any;
        if (!version) {
          this.storageService.store(KEY_LICENSE, license);
          this.store.dispatch([new SetLicense(license)]);
        }
        return license;
      })
    );
  }
  getLicenseStore() {
    return this.store.selectSnapshot(RegistrationState.license);
  }
  updateLicenseStore(license) {
    this.storageService.store(KEY_LICENSE, license);
    this.store.dispatch([new SetLicense(license)]);
  }

  updateLicense(model: License, driverLicenseId: string) {
    return this.driverApi
      .updateLicense(model, driverLicenseId)
      .pipe(map((response: Response) => response.data as License));
  }
  getDqfsWithDriverType(dqfs: any[]): any[] {
    const driver = this.getDriver();
    const currentDate = new Date();

    const withdrawnDQFs = dqfs
      .filter(dqf => dqf.status === 'Withdrawn' && dqf.withdrawalDate)
      .filter(dqf => {
        const withdrawalDate = new Date(dqf.withdrawalDate);
        const daysDifference = Math.floor((currentDate.getTime() - withdrawalDate.getTime()) / (1000 * 60 * 60 * 24));
        return daysDifference <= 7;
      });
    const driverAssociations = driver?.companyAssociations;
    if (!driverAssociations && dqfs?.length === 1 && dqfs[0].carrierCompanyId === driver?.companyAssociatedId) {
      return dqfs;
    }
    const associatedDqfs = dqfs.filter(dqf =>
      driverAssociations?.some(driver => driver.id?.includes(dqf?.carrierCompanyId))
    );
    associatedDqfs.forEach(dqf => {
      const carrierAssociation = driverAssociations?.find(c => c.id.includes(dqf.carrierCompanyId));
      dqf.driverType = DRIVER_TYPE_MAP[carrierAssociation.driverType];
    });
    return [...associatedDqfs, ...withdrawnDQFs];
  }
  updateDriverStage(driver: any): any {
    return this.driverApi.updateDriverStage(driver).pipe(map((response: any) => response));
  }
  private createDriverStateModel(driver: Driver): DriverStateModel {
    const driverStateModel = {
      id: driver.id,
      driverID: driver.driverID,
      firstName: driver.firstName,
      lastName: driver.lastName,
      email: driver.email,
      identityStatus: driver.identityStatus,
      referralCode: driver.referralCode,
      phoneNumber: driver.phoneNumber,
      birthday: driver.birthday,
      gender: driver.gender,
      address: driver.address,
      city: driver.city,
      state: driver.state,
      zip: driver.zip,
      country: driver.country,
      licenseType: driver.licenseType,
      driverType: driver.driverType,
      issuingAuthority: driver.issuingAuthority,
      createdDate: driver.createdDate,
      modifiedDate: driver.modifiedDate,
      notification: driver.notification,
      stats: driver.stats,
      documentIdentityConsent: driver.documentIdentityConsent,
      companyAssociatedId: driver.companyAssociatedId,
      sin: driver.sin,
      companyDriverType: driver.companyDriverType,
      companyAssociations: driver?.companyAssociations,
      employmentRequirementsCompleted: driver?.employmentRequirementsCompleted,
      residenceHistoryRequirementsCompleted: driver?.residenceHistoryRequirementsCompleted,
      stage: driver?.stage,
      emergencyContacts: driver?.emergencyContacts,
      workStatus: driver?.workStatus
    } as DriverStateModel;
    return driverStateModel;
  }

  private createDriver(driverStateModel: DriverStateModel): Driver {
    const driver = {
      id: driverStateModel.id,
      driverID: driverStateModel.driverID,
      firstName: driverStateModel.firstName,
      lastName: driverStateModel.lastName,
      email: driverStateModel.email,
      identityStatus: driverStateModel.identityStatus,
      referralCode: driverStateModel.referralCode,
      phoneNumber: driverStateModel.phoneNumber,
      birthday: driverStateModel.birthday,
      gender: driverStateModel.gender,
      address: driverStateModel.address,
      city: driverStateModel.city,
      state: driverStateModel.state,
      zip: driverStateModel.zip,
      country: driverStateModel.country,
      licenseType: driverStateModel.licenseType,
      driverType: driverStateModel.driverType,
      issuingAuthority: driverStateModel.issuingAuthority,
      createdDate: driverStateModel.createdDate,
      notification: driverStateModel.notification,
      modifiedDate: driverStateModel.modifiedDate,
      documentIdentityConsent: driverStateModel.documentIdentityConsent,
      companyAssociatedId: driverStateModel.companyAssociatedId,
      sin: driverStateModel.sin,
      companyDriverType: driverStateModel.companyDriverType,
      companyAssociations: driverStateModel?.companyAssociations,
      stats: driverStateModel.stats,
      employmentRequirementsCompleted: driverStateModel.employmentRequirementsCompleted,
      residenceHistoryRequirementsCompleted: driverStateModel.residenceHistoryRequirementsCompleted,
      stage: driverStateModel?.stage,
      emergencyContacts: driverStateModel?.emergencyContacts,
      workStatus: driverStateModel?.workStatus
    } as Driver;
    return driver;
  }
  private createProfilePicture(extension: string): Observable<ProfilePictureReponse> {
    const sendProfilePictureRequest = { extension } as ProfilePictureRequest;
    return this.driverApi.createProfilePicture(sendProfilePictureRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as ProfilePictureReponse;
        }

        return undefined;
      })
    );
  }

  retriveConsentDetails(consentId: string, code: string): Observable<DriverConsent[]> {
    return this.driverApi
      .retriveConsentDetails(consentId, code)
      .pipe(map((response: any) => response?.data as DriverConsent[]));
  }

  approveOrRejectConsent(consent: any, consentRequestId: string, code: string): any {
    return this.driverApi.approveOrRejectConsent(consent, consentRequestId, code);
  }
}
