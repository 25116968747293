<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxFlexAlign="center" fxLayout="column" fxFlex="none">
    <img class="logo padding-top-x4" src="/assets/img/fleetoperate_logo.jpg" width="300px" />
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-x2">
    <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error" data-test="form-error-message">
      {{ errorMessage }}
    </mat-error>
    <form [formGroup]="form" #signinForm="ngForm" (ngSubmit)="onSubmit(signinForm)" fxFlex="none" fxLayout="column">
      <mat-form-field>
        <input type="email" matInput formControlName="email" data-test="email-form-field" />
        <mat-placeholder>Email</mat-placeholder>
        <mat-error *ngIf="email.touched && email.invalid" data-test="email-error-field">
          {{ getEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-hint align="start">at least 8 characters</mat-hint>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          data-test="password-form-field"
        />
        <mat-placeholder>Password</mat-placeholder>
        <mat-icon matSuffix (click)="hidePassword = !hidePassword" data-test="password-visibility-button">{{
          hidePassword ? 'visibility' : 'visibility_off'
        }}</mat-icon>
        <mat-error *ngIf="password.touched && password.invalid" data-test="password-error-field">
          {{ getPasswordErrorMessage() }}
        </mat-error>
      </mat-form-field>

      <span fxLayout class="padding-top-x4 padding-bottom-x3">
        <a fxFlex [routerLink]="" (click)="onNavigateToForgotPassword()" data-test="forgot-password-link"
          >Forgot password?</a
        >
        <a [routerLink]="" (click)="onNavigateToHelp()">Need help?</a>
      </span>

      <div class="align-self-center padding-top-md">
        By clicking Sign in, you agree to our
        <a [routerLink]="" (click)="onNavigateToTermsConditions()">Terms and Conditions</a> and
        <a [routerLink]="" (click)="onNavigateToPrivacyStatement()">Privacy Statement</a>. 
      </div>
      
      <div class="full-width padding-top-x4">
        <button
          class="full-width margin-bottom"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.pristine || !form.valid || loading"
          data-test="login-button"
        >
          Sign In
        </button>
      </div>
    </form>
  </div>
  <div fxFlexAlign="center full-width" fxLayout="column" fxFlex="none">
    <div class="center full-width signInPageLink">
      <br />
      Don't have an account? <a [routerLink]="" (click)="onNavigateToSignUp()" data-test="signup-link">Sign up</a>
    </div>
  </div>
  <div class="recaptcha-terms align-self-center padding-top-xs">
    This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy" class="recaptcha-terms-links">Privacy Policy</a> and
    <a href="https://policies.google.com/terms" class="recaptcha-terms-links">Terms of Service</a> apply.
  </div>
</div>
