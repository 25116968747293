<!-- <div fxLayoutAlign="start" fxLayout="column">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
    <span fxFlex class="title">Terms and Conditions</span>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxFlex fxLayout="column" class="padding-left-md padding-right-md">
      <span fxFlexAlign="center"></span>
      <ol class="heading-list">
        <li>
          <h2>Acceptance of Terms of Service</h2>
          <p>
            This User Agreement describes the terms by which FleetOperate Inc. ("FleetOperate"), a Canadian corporation,
            offers to users of its services access to its website www.fleetoperate.com (the “Site”) and the associated
            mobile software application platform owned and operated by FleetOperate (the “Mobile App”). As a technology
            platform, FleetOperate brings together shippers and receivers of freight, motor carriers and drivers engaged
            in the business of transporting freight for hire (“Transportation Services”) to facilitate the booking and
            management of cargo shipments ("Shipments"). The Site, Mobile App, and all services provided by FleetOperate
            therein including access to all applications, content, and downloads, together, constitute the "FleetOperate
            Services”.
          </p>
          <p>
            FleetOperate offers a platform to connect Shippers, Carriers, and Drivers, but does not provide actual
            Transportation Services or act in any way as a carrier of cargo. FleetOperate does not take possession,
            custody or control of any cargo. It is the obligation of Carriers and Drivers to provide Transportation
            Services, which may be scheduled through the use of the FleetOperate Services. FleetOperate takes no
            responsibility for any Transportation Services provided to Shippers by Carriers or Drivers. FleetOperate
            does not assess the suitability, legality, regulatory compliance, quality or ability of any Shipper,
            Carrier, Driver, Owner Operator or shipped items scheduled through the use of FleetOperate Services, and
            FleetOperate makes no warranty regarding the same.
          </p>
          <p>
            THIS FLEETOPERATE USER AGREEMENT AND FLEETOPERATE’S PRIVACY POLICY INCORPORATED HEREIN BY REFERENCE
            (COLLECTIVELY, THE “AGREEMENT”), SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE
            FLEETOPERATE SERVICES. IN CONSIDERATION FOR YOUR RIGHT TO ACCESS AND USE THE FLEETOPERATE SERVICES, THE
            RECEIPT AND SUFFICIENCY OF WHICH ARE HEREBY ACKNOWLEDGED, YOU AGREE TO THE TERMS AND CONDITIONS SET OUT
            BELOW.
          </p>
          <p>
            BY CLICKING “I ACCEPT” AND REGISTERING FOR, AND/OR USING THE FLEETOPERATE SERVICES IN ANY MANNER, YOU AND
            THE ENTITY OR ORGANIZATION EMPLOYING OR ENGAGING YOU, OR THAT YOU OTHERWISE REPRESENT, ACCEPT AND AGREE TO
            BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU ARE ACCEPTING THE TERMS AND CONDITIONS
            CONTAINED IN THIS AGREEMENT ON BEHALF OF SUCH AN ENTITY OR ORGANIZATION, YOU REPRESENT AND WARRANT THAT YOU
            HAVE FULL LEGAL AUTHORITY TO BIND SUCH AN ENTITY OR ORGANIZATION FOR THE PURPOSES OF THIS AGREEMENT. YOU MAY
            NOT ACCESS OR USE THE FLEETOPERATE SERVICES OR ACCEPT THIS AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD.
            IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE FLEETOPERATE
            SERVICES.
          </p>
        </li>
        <li>
          <h2>Defined Terms</h2>
          <p>
            For the purposes of this Agreement, unless there is something in the subject matter or context inconsistent
            therewith, words denoting the singular include the plural, and vice versa. In this Agreement the following
            capitalized terms and expressions shall have the following meanings:
          </p>
          <ol class="subheading-list">
            <li>
              <h3>Canadian Carrier</h3>
              <p>
                A "Canadian Carrier" means a Carrier originating in Canada and engaged in Canadian Origin Shipments, and
                as further described in Section 7.
              </p>
            </li>
            <li>
              <h3>Canadian Origin Shipment</h3>
              <p>
                "Canadian Origin Shipment" means any Shipment booked through the FleetOperate Services that originates,
                or is transported between points, in Canada.
              </p>
            </li>
            <li>
              <h3>Carrier</h3>
              <p>
                "Carrier" means the legal entity identified by you as a "Carrier" during the registration process to
                access the FleetOperate Services, and as further described in Sections 5, 6 and 7.
              </p>
            </li>
            <li>
              <h3>Driver</h3>
              <p>
                "Driver" means the legal entity identified by you as a "Driver" during the registration process to
                access the FleetOperate Services, and as further described in Section 8.
              </p>
            </li>
            <li>
              <h3>Equipment</h3>
              <p>
                "Equipment" means all equipment used by Carriers or Drivers in the provision of Transportation Services,
                and as further described in Subsection 5.2.
              </p>
            </li>
            <li>
              <h3>Hazardous Waste</h3>
              <p>
                "Hazardous Waste" means waste that meets the definition of Hazardous Waste in the
                <i>Interprovincial Movement of Hazardous Waste Regulations</i>, SOR/2002-301, as amended from time to
                time.
              </p>
            </li>
            <li>
              <h3>Owner Operator</h3>
              <p>
                An "Owner Operator" means a Driver who operates their own Equipment or Equipment rented through a
                FleetOperate affiliate rental company, subject to the terms of a separate agreement to be entered into,
                between the Driver and such FleetOperate affiliate rental company (the “Rental Agreement”), to the
                extent FleetOperate makes such a rental program available. Owner Operators serve the role of both Driver
                and Carrier for the purposes of the Transportation Services and operate under their own authority,
                rather than that of a separate Carrier. Owner Operators are deemed to be Carriers for the purposes of
                this Agreement, and therefore Owner Operators must meet all terms applicable to them as a Driver and all
                terms applicable to them as a Carrier, as described in Sections 5, 6, 7 and 8, respectively.
              </p>
            </li>
            <li>
              <h3>Service Provider</h3>
              <p>
                Carriers, Drivers and Owner Operators are collectively referred to as "Service Providers", and
                individually referred to as a "Service Provider".
              </p>
            </li>
            <li>
              <h3>Shipper</h3>
              <p>
                "Shipper" means the customer who engages a Carrier or Owner Operator and who pays to have cargo shipped
                through the FleetOperate Services. A Shipper may be either a sender or receiver of freight, or both.
              </p>
            </li>
            <li>
              <h3>US Carrier</h3>
              <p>
                A "US Carrier" means a Carrier originating in the United States and engaged in US Origin Shipments, and
                as further described in Section 6.
              </p>
            </li>
            <li>
              <h3>US Origin Shipment</h3>
              <p>
                "US Origin Shipment" means any Shipment booked through the FleetOperate Services that originates, or is
                transported between points, in the United States.
              </p>
            </li>
            <li>
              <h3>User</h3>
              <p>
                "User" means any user of the FleetOperate Services, which may be a Carrier, Shipper, Driver, or Owner
                Operator, but may not be a broker or a freight forwarder (unless, as a broker or freight-forwarder, the
                User-broker/freight forwarder represents a Shipper when initiating a Shipment request pursuant to the
                FleetOperate Services, in which case the broker or freight forwarder will be deemed a Shipper for the
                purposes of that Shipment). Users' use of the FleetOperate Services is governed by this Agreement.
              </p>
            </li>
            <li>
              <h3>User Content</h3>
              <p>
                "User Content" means any and all information, data, textual, audio, and/or visual content, commentary,
                feedback, ratings, reviews and other content that a User submits to, or uses with, the FleetOperate
                Services. User Content includes the information provided in a Shipment request or acceptance. User
                warrants: (i) the completeness and accuracy of all User Content posted to the FleetOperate Services; and
                (ii) that User will provide any and all information as prompted or requested by the FleetOperate
                Services, for the purposes of the User's engagement with these services. Each User grants FleetOperate
                the right to use its User Content, for the purpose of delivering the FleetOperate Services.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>FleetOperate Services</h2>
          <ol class="subheading-list">
            <li>
              <h3>Service Engagement</h3>
              <p>
                FleetOperate, as a technology platform via the Mobile App and our Site, brings together Shippers,
                Carriers, and Drivers for the transportation of Shipments to, from, and between points in the United
                States and or Canada, enabling Shippers to post details of desired and requested shipping and
                transportation needs, Carriers and Drivers to accept such requests, and allowing parties involved in the
                transaction to book and manage the resulting Shipments. FleetOperate is not a Shipper, Carrier, Broker
                or Driver.
              </p>
              <p>
                Once FleetOperate accepts a Shipment for posting, the Shipment’s details, as provided by the Shipper,
                will be posted to the Site and Mobile App. A Shipment will be accepted for posting by FleetOperate upon:
                (1) The Shipper providing all mandatory information to create the Shipment; (2) The Shipper providing
                any legally required documentation for that Shipment; and (3) FleetOperate being satisfied that the
                Shipper has a sufficient credit standing. Once accepted for posting, the Shipment information will be
                presented to a network of Drivers and Carriers who are matched to the Shipment based on the Shipper's
                preferences and the requirements of the Shipment. Once a Shipment is posted, it is open to be accepted
                by Carriers or Shippers for the provision of Transportation Services. FleetOperate is under no
                obligation to accept a Shipment for posting, and it may choose to decline to post a Shipment, in its
                sole discretion. A posting of a Shipment by FleetOperate does not constitute a representation, by
                FleetOperate, that the posting is accurate or contains all information that may be relevant, to the
                Shipment. The Shipper may cancel a Shipment at any time prior to a Carrier accepting it. Carriers may
                view a list of Shipments available to them at any time and accept a Shipment through the FleetOperate
                Services. Once the Shipper selects the Carrier and the Carrier accepts a Shipment, the Carrier will be
                assigned to perform Transportation Services related to that Shipment.
              </p>
              <p>
                Carriers may use their own drivers (external to the FleetOperate Services) to provide driving services
                for the Shipment, or Carriers may engage Drivers through the FleetOperate Services to perform driving
                services for that Shipment, subsequent to the Carrier accepting the Shipment. Drivers may also act as
                their own Carrier for the purposes of a Shipment, in which case they are referred to as Owner Operators
                (and both Driver and Carrier terms will apply to them). Drivers engaged for Transportation Services by
                Carriers are selected at the Carrier's discretion.
              </p>
              <p>
                Once a Shipment has been accepted by a Carrier, FleetOperate will notify the Shipper that the Shipment
                has been accepted. It also will notify the Carrier, and the Driver (if the Shipment involves a Driver),
                that the Shipment has been assigned to them; PROVIDED, however, that FleetOperate does not guarantee
                that any attempt to accept any Shipment will be successful. FleetOperate assigns Shipments to the first
                Carrier and Driver which accepts the posting from a pool of Service Providers which are matched to the
                Shipment based on criteria such as Driver availability, rate, location, Shipment route, ratings, and
                Shipper preferences. If the original Carrier assignment fails for whatever reason, the Shipment will be
                re-posted to the FleetOperate Services, unless it has been cancelled with the Shipper before another
                Carrier accepts it.
              </p>
              <p>
                Within a reasonable time after accepting tender of cargo for transport, the Carrier shall upload to the
                FleetOperate Services their bill of lading for the Shipment. Carrier shall not name FleetOperate as
                either a shipper or consignee on any bill of lading. This bill of lading will contain the Shipment terms
                and contract of carriage between the Shipper and the dispatched Carrier. FleetOperate is not responsible
                for any Shipment terms (including the Shipment Contract, as defined below) entered into between any
                Carrier and Shipper.
              </p>
              <p>
                Unless otherwise agreed in writing by the Carrier and Shipper and communicated to FleetOperate in
                writing, freight charges stated in the FleetOperate Services and agreed to by a Carrier's acceptance of
                Shipments hereunder include the transportation of the cargo from origin to destination; the cost of any
                fuel, tolls, ferry charges or other expenses related to the operation or maintenance of your Equipment;
                and any other specialized services or Equipment contemplated in the load tender (including, but not
                limited to amounts for refrigerated trailers, lift-gate service, loading or unloading, etc.). Once a
                Shipment is completed, Carrier or Driver shall post to the FleetOperate Services a proof of delivery
                signed by the authorized recipient within 24 hours of delivery ("Proof of Delivery").
              </p>
              <p>
                FleetOperate may, as a convenience and value added service, provide Carrier, Driver and Shipper with
                access to GPS services, Google Maps or similar service to suggest routing through the FleetOperate
                Services. However, any such routing information is for your convenience only. It is not instructional or
                mandatory, and FleetOperate does not represent or warrant the accuracy of any information so provided.
              </p>
            </li>
            <li>
              <h3>Application of Terms</h3>
              <p>To the extent you are a User, the provisions in this Agreement designated for “Users” apply to you.</p>
              <p>
                To the extent you are a Shipper, the provisions in this Agreement designated for “Shippers” apply to
                you.
              </p>
              <p>
                To the extent you are a Driver, the provisions in this Agreement designated for “Drivers” apply to you.
              </p>
              <p>
                To the extent you are a Carrier, the provisions in this Agreement designated for “Carriers” apply to
                you.
              </p>
              <p>
                To the extent you are an Owner Operator, the provisions in this Agreement designated for “Carriers” and
                "Drivers" apply to you, including any provisions specific to US Carriers or Canadian Carriers, as the
                case may be.
              </p>
              <p>
                US Origin Shipments are subject to the provisions of Sections 5, 6 and 11, other provisions that
                specifically indicate that they apply to US Origin Shipments ("US Terms"), and all other provisions to
                the extent that such terms do not conflict with any US Terms.
              </p>
              <p>
                Canadian Origin Shipments are subject to the provisions of Sections 5, 7 and 12, other provisions that
                specifically indicate that they apply to Canadian Origin Shipments ("Canadian Terms"), and all other
                provisions to the extent that such terms do not conflict with any Canadian Terms.
              </p>
            </li>
            <li>
              <h3>Relationship of the Parties</h3>
              <p>
                FleetOperate (as the owner and operator of the FleetOperate Services), Shippers, Carriers and Drivers
                are all fully independent contracting parties. Any products or services offered by a Carrier or Driver
                and accepted by a Shipper or Carrier (as the case may be), are offered and accepted independently from,
                and to the full exclusion of, FleetOperate. Any price suggested by a Driver or Carrier is subject to
                negotiation between the User engaging those services and that Driver or Carrier, on their own behalf and
                for their own benefit, as fully competent contracting parties independent of FleetOperate Services. For
                greater certainty, FleetOperate provides an enabling platform to connect Shippers, Carriers, and
                Drivers, only.
              </p>
              <p>
                Your use of the FleetOperate Services may rely on information which is provided by other Users.
                FleetOperate has no responsibility to Users for anything these third parties do (or fail to do) and
                provides no warranties or guarantees about third parties or the information or services they provide.
                Your use of such services may be subject to specific terms and conditions which are set by those third
                parties. FleetOperate is not a party to any relationship between you and any third party which is formed
                as a result of an engagement for Transportation Services, or otherwise through the FleetOperate
                Services. As such, FleetOperate has no responsibility to you as regards to your relationships with these
                third parties. You acknowledge and agree that you have no recourse against FleetOperate for any acts or
                omissions of third parties, and your interaction with third parties is entirely at your own risk.
              </p>
              <p>
                The relationship between FleetOperate and each User is governed by this Agreement. By entering into this
                Agreement, Users acknowledge that other Users with whom they engage are intended third-party
                beneficiaries of various provisions of this Agreement purporting to grant rights to such Users. The
                contract governing Transportation Services for Shippers is separate and apart from this Agreement, is a
                contract between a Shipper and a Carrier (the "Shipping Contract"), and is based on the information,
                terms, conditions and provisions made or provided by those respective parties in that Shipping Contract.
                FleetOperate is not a party to the Shipping Contract and disclaims all responsibility for such Shipping
                Contract as may be entered into between the parties.
              </p>
              <p>
                If a Carrier chooses to engage a Driver through the FleetOperate Services, the contract governing
                Transportation Services as between the Carrier and Driver is also separate and apart from this
                Agreement. This contract between Carrier and Driver (the "Driving Contract") may be an employment or
                sub-contract agreement, and is based on the information, terms, conditions and provisions made or
                provided by those respective parties in that Driving Contract. Carriers are responsible for all related
                employment withholdings and agree to indemnify Shippers, Drivers and FleetOperate for their failure to
                withhold and remit applicable deductions. FleetOperate is not a party to the Driving Contract and
                disclaims all responsibility for such Driving Contract as may be entered into between the parties.
              </p>
              <p>
                This Agreement does not make you, as a User, an agent, legal representative, joint venture, or partner
                of FleetOperate for any purpose. If you are a Carrier or Driver, you understand and agree that you will
                act as an independent contractor and are in no way authorized to make any contract, warranty or
                representation on behalf of FleetOperate, or to create any obligation express or implied on behalf of
                FleetOperate. It is expressly agreed and understood that you shall not be considered under this
                Agreement as having any employment status with FleetOperate, or as being entitled to any plans,
                distributions, or benefits extended by FleetOperate to its employees. Further, any persons employed or
                engaged by a User in connection with the performance of Transportation Services shall be that User's
                employees or contractors. User assumes responsibility for the actions of its employees and contractors
                and will be solely responsible for their supervision, daily direction and control, wage rates,
                withholding income taxes, providing unemployment and disability benefits, providing workplace safety
                insurance and the manner and means through which the Transportation Services will be accomplished.
              </p>
              <p>
                AS FLEETOPERATE IS NOT A PARTY TO THE RELATIONSHIP BETWEEN YOU AND ANY OTHER SHIPPER, CARRIER OR DRIVER,
                FLEETOPERATE HAS NO RESPONSIBILITY TO YOU AS REGARDS TO: (A) THE PRODUCTS AND SERVICES YOU PURCHASE OR
                SELL (OR OFFER TO PURCHASE OR SELL) TO OR FROM SHIPPER, CARRIER OR DRIVER (AS THE CASE MAY BE); (B) YOUR
                INTERACTION WITH ANY OTHER SHIPPER, CARRIER OR DRIVER, INCLUDING THE PERFORMANCE OF ANY TRANSPORTATION
                SERVICES; (C) ANY TRANSACTIONS YOU MAY ENTER INTO OR ATTEMPT TO ENTER INTO, WITH ANY OTHER SHIPPER,
                CARRIER OR DRIVER; (D) COLLECTING OR REMITTING ANY APPLICABLE TAXES, IN RESPECT OF SUCH TRANSACTIONS.
                YOU AGREE THAT YOU WILL HAVE NO RECOURSE OR REMEDY AGAINST US, EXCEPT AS EXPRESSLY SET OUT IN THIS
                AGREEMENT.
              </p>
            </li>
            <li>
              <h3>Account Registration, Use and Security</h3>
              <p>
                In order to access the FleetOperate Services, Users must register for an account. When you register for
                an account, you will be able to join the FleetOperate Services only if your application is approved, by
                us, in our sole and absolute discretion.
              </p>
              <p>
                The security of your personal information is very important to us. We use physical, electronic, and
                administrative measures designed to secure your personal information from accidental loss and from
                unauthorized access, use, alteration, and disclosure.
              </p>
              <p>
                It is a condition of your use of the FleetOperate Services that all the information you provide on the
                FleetOperate Services is correct, current, and complete.
              </p>
              <p>
                Unfortunately, the transmission of information via the Internet is not completely secure. Although we do
                our best to protect your personal information, we cannot guarantee the security of your personal
                information transmitted to our Site or Mobile App. Any transmission of personal information is at your
                own risk. We are not responsible for circumvention of any privacy settings or security measures
                contained on the Site or Mobile App.
              </p>
              <p>
                Your provision of registration information and any submissions you make to the FleetOperate Services
                constitutes your consent to all actions we take with respect to such information consistent with our
                Privacy Policy, found at
                <u><a (click)="onNavigateToPrivacyPolicy()">this link</a></u
                >.
              </p>
              <p>
                Any username, password, or any other piece of information chosen by you, or provided to you as part of
                our registration process or security procedures, must be treated as confidential, and you must not
                disclose it to any other person or entity. You must exercise caution when accessing your account from a
                public or shared computer so that others are not able to view or record your password or other personal
                information. You understand and agree that should you be provided an account, your account is personal
                to you and you agree not to provide any other person with access to the FleetOperate Services or
                portions of them using your username, password, or other security information. You agree to notify us
                immediately of any unauthorized access to or use of your username or password or any other breach of
                security. You also agree to ensure that you logout from your account at the end of each session. You are
                responsible for any password misuse or any unauthorized access to your account.
              </p>
              <p>
                We reserve the right at any time and from time to time, to disable or terminate your account, any
                username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion
                for any or no reason, including any violation of any provision of this Agreement.
              </p>
              <p>
                You are prohibited from attempting to circumvent and from violating the security of the Site or Mobile
                App, including, without limitation: (a) accessing content and data that is not intended for you; (b)
                attempting to breach or breaching the security and/or authentication measures which are not authorized;
                (c) restricting, disrupting or disabling service to users, hosts, servers or networks; (d) illicitly
                reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting
                FleetOperate's ability to monitor the FleetOperate Services; (f) using any robot, spider, or other
                automatic device, process, or means to access the FleetOperate Services for any purpose, including
                monitoring or copying any of the material on the FleetOperate Services; (g) introducing any viruses,
                trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h)
                attacking the Site or Mobile App via a denial-of-service attack, distributed denial-of-service attack,
                flooding, mailbombing or crashing; and (i) otherwise attempting to interfere with the proper working of
                the Site or Mobile App.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Shipper Terms</h2>
          <ol class="subheading-list">
            <li>
              <h3>Representations and Warranties</h3>
              <p>
                If you are a Shipper, you hereby represent and warrant, and acknowledge that FleetOperate, Carrier and
                Driver are relying upon such representations and warranties in entering into this Agreement, that:
              </p>
              <ol class="default-list">
                <li>
                  you own or have all right and authority necessary to ship or arrange for the shipment of the
                  commodities comprising the Shipment;
                </li>
                <li>
                  you have full power and authority to enter into, execute and deliver this Agreement and to perform
                  your obligations under this Agreement, and this Agreement is a legal, valid and binding obligation;
                </li>
                <li>
                  the execution and delivery of this Agreement by you and the performance of your obligations hereunder
                  has been duly authorized by all requisite action on your part; and
                </li>
                <li>
                  no order has been made or petition filed in any bankruptcy or insolvency proceeding concerning you,
                  and you have not made or proposed any arrangement for the benefit of your creditors.
                </li>
              </ol>
            </li>
            <li>
              <h3>Covenants</h3>
              <p>If you are a Shipper, you shall:</p>
              <ol class="default-list">
                <li>
                  duly enter into a Shipping Contract with any Carriers you engage for the provision of Transportation
                  Services;
                </li>
                <li>
                  only use the FleetOperate Services to ship or tender Carriers' commodities or Shipments that originate
                  in and are destined to a point inside of the United States or Canada, and for which you are duly
                  authorized and compliant under the laws of the relevant jurisdiction(s);
                </li>
                <li>
                  use the FleetOperate Services to ship items at your own risk and agree that FleetOperate will have no
                  liability for any Shipments;
                </li>
                <li>
                  assume sole responsibility for obtaining any insurance to cover any anticipated losses or damage to
                  your Shipments;
                </li>
                <li>
                  request pick-up and delivery dates and hours that will not knowingly require Carrier or Driver to
                  violate hours-of-service requirements under applicable federal, state, provincial, or territorial law
                  of the United States or Canada, as applicable;
                </li>
                <li>
                  be solely responsible for any and all loss, damage, claims, and expenses including, but not limited
                  to, property damage, personal injury, death and reasonable legal fees, resulting from, or alleged to
                  have resulted from, the commodities that you ship through the FleetOperate Services including but not
                  limited to your failure to properly load and secure the commodities or an inherent vice in the
                  commodities; and
                </li>
                <li>
                  pay FleetOperate the Shipment Fee as defined in and in accordance with the provisions of the Payment
                  Terms section of this Agreement.
                </li>
              </ol>
              <p>
                Additionally, you understand and agree that FleetOperate is not responsible for Carriers or Drivers that
                are accepted to transport your Shipments through the FleetOperate Services. You also consent to
                FleetOperate tracking your Shipment with Carrier-installed or Carrier-provided GPS technology in order
                to offer a better solution on Shipment logistics, including tracing and scheduling Shipments while in
                transit, and consent to Carriers, Drivers, and others having live access to a Shipment geolocation
                through such technology without your additional consent. You also acknowledge that location can be
                misinterpreted or misclassified due to a number of factors and that FleetOperate is not responsible for
                any loss, damage or expense arising from or related to this tracking service.
              </p>
            </li>
            <li>
              <h3>Non-solicitation</h3>
              <p>
                Shippers shall not contact any Carrier or Driver directly, or indirectly except through the FleetOperate
                Services, to solicit, accept, or book Shipments with the Carrier or Driver during the Term of this
                Agreement and for a period of twelve (12) months from the last date of contact through the FleetOperate
                Services between Carrier or Driver and Shipper. FleetOperate reserves the right to terminate Shipper’s
                account or this Agreement in its sole discretion for a breach of this Subsection 4.3. The foregoing
                actions shall be in addition to any other remedies available to FleetOperate at law, including but not
                limited to recovery of damages or an injunction order.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>General Carrier Terms</h2>
          <p>
            The following terms of this Section 5 apply <u>to all Carriers</u>, regardless of whether they are US
            Carriers or Canadian Carriers. For terms specific to US Carriers and Canadian Carriers, please see Sections
            6 and 7, respectively.
          </p>
          <ol class="subheading-list">
            <li>
              <h3>General Carrier Covenants</h3>
              <p>If you are a Carrier, you shall:</p>
              <ol class="default-list">
                <li>
                  duly enter into a Shipping Contract with any Shippers for whom you provide Transportation Services;
                </li>
                <li>
                  duly enter into a Driving Contract with any Drivers you engage for the provision of Transportation
                  Services;
                </li>
                <li>
                  prior to moving your first Shipment through the FleetOperate Services, provide to FleetOperate
                  certificate(s) of insurance evidencing <mark>FleetOperate as an Additional Insured</mark> and coverage
                  amounts and types to be specified by FleetOperate at the time of sign-up and otherwise in writing,
                  from time to time. Such insurance policies are to be held with insurance companies maintaining a
                  rating from A. M. Best of A- or higher, and Carrier shall maintain such insurance for the term of this
                  Agreement;
                </li>
                <li>
                  only use the FleetOperate Services to ship Shipments that originate in and are destined to a point
                  inside of the United States or Canada, and for which you are duly authorized and compliant under the
                  laws of the relevant jurisdiction(s);
                </li>
                <li>
                  furnish all Equipment required for the performance of your Transportation Services with respect to
                  every Shipment, including temperature controlled vehicles when needed, and will maintain all such
                  Equipment in good repair and condition;
                </li>
                <li>
                  be solely responsible for all personnel you engage or retain to provide services under this Agreement,
                  including paying or providing such personnel’s wages, insurance, training and other benefits;
                </li>
                <li>
                  if engaging a Driver through the FleetOperate Services, only engage Drivers who have complied with
                  their obligations as set out in Section 8;
                </li>
                <li>
                  not cause or permit any Shipment to be transported by any third-party motor carrier, including in
                  interline service or by brokering or trip-leasing, or by any other substitute mode of transportation;
                </li>
                <li>
                  proceed to pick up all accepted Shipments in accordance with the Shipper’s scheduled pick-up date and
                  time;
                </li>
                <li>
                  notify Shipper and FleetOperate immediately when free time has expired and detention charges are about
                  to be incurred. Such notification is to be provided through the Mobile App, or by an email or text
                  message which includes the Shipment documentation. For clarity, such notification is for informational
                  purposes only and shall in no circumstances alter any existing agreement between the parties unless
                  otherwise agreed to and provided for in writing;
                </li>
                <li>
                  except as provided otherwise in this Agreement, look solely to FleetOperate for payment of any fees or
                  charges due to you in connection with your Transportation Services under this Agreement, and you
                  expressly waive any claim against any third party consignee under the Bills of Lading Act (Canada),
                  the Mercantile Law Amendment Act (Ontario), the Bills of Lading Act (Nova Scotia) and any other
                  substantially similar provincial, territorial, federal and state, and/or common law that exists or
                  which may be enacted or exist (collectively, the “Consignee Recovery Laws”);
                </li>
                <li>
                  if you reasonably believe you are entitled to extra accessorial charges, notify FleetOperate and
                  Shipper within 24 hours of the scheduled delivery time as stated on the Shipment record of those
                  charges of which you are aware at that time, provided that in no circumstances will FleetOperate be
                  responsible for any charges that are not accepted by a Shipper. The Shipment record includes, but is
                  not limited to, the bill of lading, the Shipment request that the Shipper created through the
                  FleetOperate Services, and information such as origination, destination, pick up time, deliver time,
                  and type of goods. Notification is to be provided by email along with documentation evidencing the
                  completion of the delivery, such as a receipt indicating that the Shipment has been received by the
                  intended recipient;
                </li>
                <li>
                  not claim, and hereby waive and release any right to claim, any lien on any Shipment, and you will not
                  withhold delivery of any Shipment on account of any dispute about any fees or charges claimed under
                  this Agreement or in order to secure payment of such fees or charges; and
                </li>
                <li>
                  be solely responsible for any and all liability resulting from, or alleged to have resulted from, the
                  transportation of, or failure to transport, any Shipment arranged through the FleetOperate Services,
                  including, but not limited to, property damage, cargo loss, damage, delay, personal injury, and death.
                </li>
              </ol>
            </li>
            <li>
              <h3>Equipment</h3>
              <p>
                Carriers warrant that they shall perform all Transportation Services pursuant to this Agreement with
                Equipment that is regularly maintained and is in good order, condition and repair and that meets with
                all applicable federal, state, provincial or territorial laws, rules and regulations. This requirement
                applies regardless of whether the Equipment in question is owned, leased, or rented by you. You will not
                supply Equipment that has been used to transport Hazardous Wastes of any kind, including, solid, liquid,
                or other hazardous waste, regardless of whether such waste meets the definition in 40 C.F.R. §261.1 et.
                Seq or other equivalent legislation. Equipment furnished for Transportation Services must be clean, dry,
                leak proof, free from harmful or offensive odor, sanitary, and free of any contamination, suitable for
                the particular commodity being transported and which will not cause in whole or in part adulteration of
                the commodity, including, but not limited to, adulteration as defined in 21 U.S.C § 342.
              </p>
            </li>
            <li>
              <h3>Shipper Load and Count</h3>
              <p>
                Shipper, or the facility handling the Shipment on behalf of the Shipper shall be solely responsible for
                loading, unloading, and securing cargo it transports under this Agreement and shall pre-load and seal
                the Shipment prior to a Driver's or Carrier's arrival at the pick-up destination, unless Shipper
                expressly requires Carrier to do so and indicates so accordingly when it books the Shipment. Shipments
                pre-loaded and sealed by the Shipper, or the facility handling the Shipment on behalf of the Shipper,
                shall have the applicable bill of lading bear a “Shipper Load and Count”, “SLC” or similar designation.
                Carrier is solely responsible to Shipper for ensuring its driver properly loads, secures, blocks and
                braces all Equipment and cargo. User acknowledges that FleetOperate will never be in possession of any
                cargo being transported in connection with the use of the FleetOperate Services, and that FleetOperate
                will not be responsible or have any role in the securement of cargo for transportation.
              </p>
            </li>
            <li>
              <h3>Non-Solicitation</h3>
              <p>
                Carriers shall not contact any Shipper or Driver directly, or indirectly except through the FleetOperate
                Services, to solicit, accept, or book shipments with Shipper or Driver during the Term of this Agreement
                and for a period of twelve (12) months from the last date of contact through the FleetOperate Services
                between Carrier and Shipper or Driver, as the case may be. FleetOperate reserves the right to terminate
                Carrier’s account or this Agreement in its sole discretion for a breach of this Subsection 5.4. The
                foregoing actions shall be in addition to any other remedies available to FleetOperate at law, including
                but not limited to recovery of damages or an injunction order.
              </p>
            </li>
            <li>
              <h3>Independent Contractor</h3>
              <p>
                As a Carrier who accesses or uses the FleetOperate Services, you are an independent contractor and
                remain solely responsible for screening, selecting, hiring, training, supervising, managing, assigning,
                and dispatching any drivers; as well as for the inspection and maintenance of your motor vehicle
                equipment and accessories. You are solely responsible for your own actions, omissions, training,
                oversight, compliance with regulatory and safety requirements, and all management of your equipment,
                services, drivers, employees, contractors, agents and servants (including, without limitation, any
                Drivers you engage using the FleetOperate Services). You maintain sole control over the methods and
                results by which you perform Transportation Services, and retain the sole duty to provide, maintain,
                manage and control the equipment, personnel, and expertise required to transport Shipments. Carrier is
                solely responsible for the acts and omissions of any employees, agents, contractors and subcontractors
                used by Carrier in the performance of Transportation Services. FleetOperate is not an agent of you or
                any Carrier, and no Carrier is an agent of FleetOperate.
              </p>
              <p>
                FLEETOPERATE IS NOT A MOTOR CARRIER. NO INTERPRETATION OF WRITTEN OR ORAL REMARKS IN ANY AGREEMENT OR
                DOCUMENT SHALL BE CONSTRUED TO IMPLY FLEETOPERATE IS A MOTOR CARRIER, OR THAT FLEETOPERATE IS SUBJECT TO
                THE REGULATORY OR LEGAL REQUIREMENTS OR LIABILITIES OF A MOTOR CARRIER. FLEETOPERATE HAS NO
                RESPONSIBILITY OR LIABILITY FOR ANY MOTOR CARRIER OR TRANSPORTATION SERVICES PROVIDED TO ANY SHIPPER OR
                ANY OTHER PARTY USING THE FLEEOPERATE SERVICES. NO INTERLINING ARRANGEMENT IS CREATED BY THIS AGREEMENT.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>US Carrier Terms</h2>
          <p>
            The following provisions in this Section 6 apply to Carriers engaged in US Origin Shipments, to the extent
            US Origin Shipments are made available through, and enabled as part of, the Transportation Services.
          </p>
          <ol class="subheading-list">
            <li>
              <h3>US Carrier Representations and Warranties</h3>
              <p>
                If you are a US Carrier you hereby represent and warrant, and acknowledge that FleetOperate, Shippers
                and Drivers are relying upon such representations and warranties in entering into this Agreement, that:
              </p>
              <ol class="default-list">
                <li>you satisfy all terms applicable to US Carriers;</li>
                <li>
                  you have completed the FleetOperate registration process and have provided FleetOperate:
                  <ol type="a">
                    <li>your assigned “MC” or “DOT” number; and</li>
                    <li>a completed W-9 form.</li>
                  </ol>
                </li>
                <li>
                  you are duly authorized to provide Transportation Services as a motor carrier of commodities and
                  desire to provide such services for Shipments;
                </li>
                <li>
                  you have all applicable licenses, certificates, permits, approvals, and authority necessary to provide
                  the Transportation Services subject to this Agreement, including the Uniform Intermodal Interchange &
                  Facilities Access Agreement in place for drayage services;
                </li>
                <li>
                  you have a drug and alcohol testing program meeting DOT and applicable federal, foreign, state and
                  local requirements; and
                </li>
                <li>
                  in cases where you are transporting a Shipment into Canada, you also make the representations and
                  warranties set forth in Subsection 7.1, below.
                </li>
              </ol>
            </li>
            <li>
              <h3>US Carrier Covenants</h3>
              <p>If you are a US Carrier, you shall:</p>
              <ol class="default-list">
                <li>
                  comply with all applicable American federal and state laws and regulations related to providing such
                  Transportation Services, including without limitation those of the U.S. Department of Transportation
                  and its federal agencies and, if applicable, those of the U.S. Food and Drug Administration;
                </li>
                <li>
                  immediately notify FleetOperate if your U.S. DOT safety or fitness rating has changed to conditional,
                  unsatisfactory, or unfit or if the scores for your FMCSA administered BASICs exceed the applicable CSA
                  Intervention Thresholds in two or more categories;
                </li>
                <li>
                  be solely responsible for and accept liability for loss, damage, injury, or delay to the Shipments you
                  transport, in accordance with the provisions set out in Section 11;
                </li>
                <li>
                  except with respect to any limitations set out in Section 11, defend, indemnify, and hold harmless
                  FleetOperate, Shipper, FleetOperate’s customers, the consignor and consignee on each Shipment from and
                  against all claims, losses, damages, liabilities, and expenses, including attorneys’ fees, arising out
                  of or relating to:
                  <ol type="a">
                    <li>your performance of Transportation Services under this Agreement;</li>
                    <li>violation of any applicable law;</li>
                    <li>breach of any material term or condition of this Agreement;</li>
                    <li>
                      inaccuracy or misrepresentation of any representation or warranty given by you under this
                      Agreement; and
                    </li>
                    <li>your negligent acts or omissions and those of your employees, agents and contractors;</li>
                  </ol>
                </li>
                <li>
                  and in cases where you are transporting a Shipment into Canada, agree to the covenants set forth in
                  Subsection 7.2, below.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>Canadian Carrier Terms</h2>
          <p>The following provisions in this Part 7 apply to Carriers engaged in Canadian Origin Shipments.</p>
          <ol class="subheading-list">
            <li>
              <h3>Canadian Carrier Representations and Warranties</h3>
              <p>
                If you are a Canadian Carrier you hereby represent and warrant, and acknowledge that FleetOperate,
                Shippers and Drivers are relying upon such representations and warranties in entering into this
                Agreement, that:
              </p>
              <ol class="default-list">
                <li>you satisfy all terms applicable to Canadian Carriers;</li>
                <li>
                  you have completed the FleetOperate registration process and have provided FleetOperate: (i) your
                  Safety Fitness Certificate number for each province and territory, as applicable, where you have
                  vehicles registered and plated;
                </li>
                <li>
                  you are duly authorized by all applicable authorities and under all applicable laws to provide
                  Transportation Services as a motor carrier of commodities and desire to provide such services for
                  Shipments;
                </li>
                <li>
                  you have a drug and alcohol testing program meeting US DOT requirements, to the extent they are
                  applicable to the you, together with any other drug and alcohol requirements that may apply under
                  Canadian law, from time to time; and
                </li>
                <li>
                  in cases where you are transporting a Shipment into the United States, you also make the
                  representations and warranties set forth in Subsection 6.1, above.
                </li>
              </ol>
            </li>
            <li>
              <h3>Canadian Carrier Covenants</h3>
              <p>If you are a Canadian Carrier, you shall:</p>
              <ol class="default-list">
                <li>
                  comply with all applicable Canadian provincial, territorial, federal laws and regulations, and common
                  law related to providing such Transportation Services, including, but not limited to: the Motor
                  Vehicle Transport Act, Motor Carrier Safety Fitness Certificate Regulations, Commercial Vehicle
                  Drivers Hours of Service Regulations, Transportation of Dangerous Goods Regulations, and National
                  Safety Code Standards;
                </li>
                <li>
                  immediately notify FleetOperate if your safety rating(s) has fallen below “satisfactory” or if you
                  receive notice from any issuing authority that your safety rating(s) may be rendered inactive,
                  suspended or revoked;
                </li>
                <li>
                  be solely responsible for and accept liability for loss, damage, injury, or delay to the Shipments you
                  transport, in accordance with the provisions set out in Section 12;
                </li>
                <li>
                  except with respect to any limitations set out in Section 12 above, defend, indemnify, and hold
                  harmless FleetOperate, Shipper, FleetOperate’s customers, the consignor and consignee on each Shipment
                  from and against all claims, losses, damages, liabilities, and expenses, including attorneys’ fees,
                  arising out of or relating to:
                  <ol type="a">
                    <li>your performance of Transportation Services under this Agreement;</li>
                    <li>violation of any applicable law;</li>
                    <li>breach of any material term or condition of this Agreement;</li>
                    <li>
                      inaccuracy or misrepresentation of any representation or warranty given by you in this Agreement;
                      and
                    </li>
                    <li>your negligent acts or omissions and those of your employees, agents and contractors;</li>
                  </ol>
                </li>
                <li>
                  and in cases where you are transporting a Shipment into the United States, you agree to the covenants
                  set forth in Subsection 6.2, above.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>Driver Terms</h2>
          <p>
            The following provisions apply to Drivers engaging with the FleetOperate platform. If you are Driver who is
            an Owner-Operator, then the terms that apply to you as a Carrier and you as a Driver will both apply.
          </p>
          <p>
            In order to engage as a Driver on the FleetOperate platform you are required to meet the following terms:
          </p>
          <ol class="default-list">
            <li>
              you currently possess and agree to maintain, at your sole cost and expense, a valid and current Commercial
              Driver's License as required for the class of vehicle for which you will provide Transportation Services
              in Canada, or a valid and current Class A and B Commercial Driver's License or equivalent for driving in
              the United States, or both, as applicable;
            </li>
            <li>
              you currently possess and agree to maintain insurance in the following minimum amounts and are in
              compliance with such policies of liability insurance:
              <ol type="a">
                <li>Commercial General Liability: $2,000,000</li>
                <li>Auto Liability: $2,000,000</li>
                <li>
                  Contingent Cargo Liability: The greater of $100,000 or the value of the replacement cost of the
                  Shipment
                </li>
              </ol>
            </li>
            <li>
              for all classes of insurance listed in Subsection 8(2), you agree to name FleetOperate as an Additional
              Insured and to provide FleetOperate with a Certificate of Insurance evidencing the required insurance
              amounts and FleetOperate's Additional Insured status
              <mark>with 30 days’ notice of cancellation of any such insurance to be provided to FleetOperate</mark>;
            </li>
            <li>
              you possess all licenses or certifications required by federal, state, provincial or territory law,
              including a DOT Medical Card if driving in the United States;
            </li>
            <li>you have a minimum of three (3) years of commercial driving experience;</li>
            <li>
              you have never had your license suspended or revoked in any state, province or territory in the United
              States or Canada;
            </li>
            <li>
              you have not received more than two (2) citations for moving violations within the two (2) year period
              preceding the date of your registration as a Driver with FleetOperate;
            </li>
            <li>
              you understand that FleetOperate may obtain personal information about you, including your criminal and
              driving records, as set out in our Privacy Policy, which can be accessed here:
              <u><a (click)="onNavigateToPrivacyPolicy()">link to Piravcy Policy</a></u
              >;
            </li>
            <li>
              you agree to take a drug and alcohol test (the "D&A Test") provided through an external vendor of the
              FleetOperate network as part of the registration process to become a Driver, and subsequently, as required
              from time to time by federal, state, provincial, territorial or local law;
            </li>
            <li>
              you agree that FleetOperate may collect the report from such D&A Test (the "D&A Report") from the external
              vendor;
            </li>
            <li>
              you agree that FleetOperate may share the D&A Report with any prospective Shipper or Carrier that hires
              you; and
            </li>
            <li>
              you agree that FleetOperate may share the D&A Report with relevant authorities for audit or compliance
              purposes.
            </li>
          </ol>
          <ol class="subheading-list">
            <li>
              <h3>Representations and Warranties</h3>
              <p>
                If you are a Driver, you hereby represent, warrant, and acknowledge that FleetOperate, Carriers and
                Shippers are relying upon such representations and warranties in entering into this Agreement:
              </p>
              <ol class="default-list">
                <li>
                  you have satisfied the Driver terms that are set out in clauses (1) to (12) of the preceding
                  paragraph;
                </li>
                <li>
                  you have completed the FleetOperate registration process and have provided FleetOperate the required
                  and necessary documentation;
                </li>
                <li>
                  you are duly and legally authorized to provide Transportation Services as a Driver and desire to
                  provide such services;
                </li>
                <li>
                  you satisfy one of the following conditions:
                  <ol type="a">
                    <li>
                      You are either actively engaged in an employment or contractual relationship with a Carrier;
                    </li>
                    <li>You are registered on the FleetOperate platform as an Owner Operator; or</li>
                    <li>
                      You are willing and approved to hire Equipment from a FleetOperate platform affiliated Equipment
                      Rental services, subject to availability (in which case, you will be acting as an Owner Operator
                      and the obligations in this Agreement which apply to Owner Operators, will apply to you).
                    </li>
                  </ol>
                </li>
                <li>
                  Equipment:
                  <ol type="a">
                    <li>
                      Drivers warrant that they shall perform all Transportation Services pursuant to this Agreement
                      with Equipment that is regularly maintained and is in good order, condition and repair and that
                      meets with all applicable federal, state, provincial or territorial laws, rules and regulations.
                      This requirement applies regardless of whether the Equipment in question is owned, leased, or
                      rented by you. You will not supply Equipment that has been used to transport Hazardous Wastes of
                      any kind, including, solid, liquid, or hazardous waste, regardless of whether such waste meets the
                      definition in 40 C.F.R. §261.1 et. Seq or other equivalent legislation. Equipment furnished for
                      transporting cargo must be clean, dry, leak proof, free from harmful or offensive odor, sanitary,
                      and free of any contamination, suitable for the particular commodity being transported and which
                      will not cause in whole or in part adulteration of the commodity, including, but not limited to,
                      adulteration as defined in 21 U.S.C § 342.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3>Covenants</h3>
              <p>If you are a Driver, you shall:</p>
              <ol class="default-list">
                <li>
                  duly enter into a Driving Contract with any Carriers for whom you provide Transportation Services;
                </li>
                <li>
                  comply with all applicable provincial, territorial, federal laws and regulations, and common law
                  related to providing such Transportation Services, and at all times while registered as a Driver,
                  possess all licenses or certifications required by federal, state, provincial or territory law,
                  including a DOT Medical Card if driving in the United States;
                </li>
                <li>
                  remain in compliance with valid policies of liability insurance coverage provided to you by
                  FleetOperate;
                </li>
                <li>
                  not engage in reckless behavior while driving, or permit an unauthorized third party to accompany you
                  in a vehicle while providing Transportation Services;
                </li>
                <li>
                  only provide Transportation Services using the vehicle provided to you by a Carrier or by yourself, as
                  an Owner Operator;
                </li>
                <li>not attempt to defraud FleetOperate, Carriers or Shippers using the FleetOperate Services;</li>
                <li>
                  provide any further necessary authorizations to facilitate FleetOperate’s access to any additional
                  records, as required by FleetOperate to perform its duties under this Agreement, including, but not
                  limited to:
                  <ol type="a">
                    <li>performing background checks and reference checks;</li>
                    <li>validating training, education and certificates;</li>
                    <li>
                      collecting and verifying any documents or authorizations such as driver's licence, driver extract,
                      visa, border crossing authority, port access authority, hazmat or dangerous goods carrying
                      authority; and
                    </li>
                    <li>
                      collecting information required for applying for or responding to any specify Shipment related
                      needs, such as TWIC card application to enter a port in US, registration with relevant port
                      authorities, and remaining eligible driving hours in a week.
                    </li>
                  </ol>
                  Any personal information so collected will be managed in accordance with our Privacy Policy, which can
                  be accessed here: <u><a (click)="onNavigateToPrivacyPolicy()">link to Piravcy Policy</a></u
                  >; and
                </li>
                <li>
                  pay all applicable federal, state, provincial, territorial, and local taxes based on your provision of
                  Transportation Services.
                </li>
              </ol>
            </li>
            <li>
              <h3>Access to Driver Records & Documents</h3>
              <p>
                If a Driver experiences a collision or accident in the course of Transportation Services under the
                FleetOperate platform, FleetOperate will provide the Shipper or Carrier who has engaged that Driver
                access to the Driver's records and documents that are maintained by FleetOperate, in respect of the
                Driver.
              </p>
              <p>
                Our drug and alcohol-testing program meets the regulatory requirements (49 CFR, Parts 382 and 40). Our
                tests are conducted using DOT defined procedures at certified laboratories.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Payment Terms</h2>
          <ol class="subheading-list">
            <li>
              <h3>Shipper Payment</h3>
              <p>
                For each Shipment, Shipper will pay FleetOperate the price or fee FleetOperate quoted Shipper and listed
                to Shipper when notifying Shipper that its applicable Shipment had been “booked” and accepted for
                posting on the FleetOperate Services (“Shipment Fee”). The Shipment Fee is calculated based on dynamics
                of the market, including: availability of Drivers in the geographical location; historical prices for
                the type of Shipment and Shipment route; Carrier preferences; the type of load contained in the
                Shipment; duration and time of the Shipment, and the Service Provider Fee provided by the Carrier, plus
                a markup. If you are a Shipper and have provided FleetOperate a valid credit card or your bank account
                information, YOU HEREBY AUTHORIZE FLEETOPERATE EITHER TO BILL YOUR CREDIT CARD OR TO PROCESS A CHEQUE
                FROM YOUR BANK ACCOUNT, AS APPROPRIATE, FOR THE SHIPMENT FEE IMMEDIATELY AFTER THE SHIPMENT IS BOOKED,
                AND YOU AGREE THAT NO ADDITIONAL NOTICE OR CONSENT IS REQUIRED. YOU REPRESENT AND WARRANT THAT YOU HAVE
                THE LEGAL RIGHT TO USE ANY CREDIT CARD(S), CHEQUES, OR OTHER PAYMENT MEANS USED TO INITIATE ANY
                TRANSACTION AND THAT ALL INFORMATION YOU PROVIDE FLEETOPERATE OR ITS THIRD-PARTY PAYMENT PROCESSOR IS
                ACCURATE, CURRENT, AND COMPLETE. You agree to immediately notify FleetOperate of any change in your
                billing address or the credit card or bank account used for payment hereunder. If FleetOperate processes
                a cheque from your bank account, your cheque will be converted into an electronic cheque and presented
                immediately via the Automated Clearing House system. In the event a cheque is subsequently returned for
                non-sufficient funds, or your credit card charge is disputed or otherwise subject to a chargeback fee,
                you agree that FleetOperate may add a returned-cheque fee of $25.00 to the amount of the returned cheque
                and take any action available at law or in equity to collect such full amount. YOU AGREE TO PAY THE
                RETURNED-CHEQUE FEE, THE SHIPMENT FEE, INTEREST AT A RATE OF 5% PER ANNUM, AND ANY COLLECTION CHARGES
                (INCLUDING LEGAL FEES) FLEETOPERATE INCURS. In the event you contend an unauthorized charge using a
                credit card or electronic cheque has occurred, you should consult your bank’s rules regarding refunds
                and reversals. FleetOperate complies with all legal requirements of your state, province, or territory’s
                applicable laws regarding providing refunds for unauthorized charges. When a charge occurs, FleetOperate
                will send you an email. This is your electronic receipt.
              </p>
            </li>
            <li>
              <h3>Payment to Carrier (Service Provider Remittance)</h3>
              <p>
                For each Shipment, FleetOperate will pay Carrier the price listed to Carrier when Carrier accepted the
                Shipment (the Carrier's “Service Provider Fee”). Carrier is entitled to its Service Provider Fee only
                after completion of the Shipment, and the payment order to Carrier will be made up to thirty (30)
                calendar days after FleetOperate’s receipt of the Proof of Delivery in accordance with this Agreement
                (it may take up to 5 additional business days for Carrier to receive the money due to third party
                payment processing). Completion of the Shipment for the purposes of payment to Carrier is determined
                upon receipt of Proof of Delivery and acknowledgement of such Proof of Delivery by the Shipper through
                the FleetOperate platform. FleetOperate will only pay Carrier if the Shipment Fee has been received in
                full and all Transportation Services related to that Shipment have been completed. Should the Shipment
                Fee not be paid by the Shipper, the Shipment will not be booked and the Carrier will not be entitled to
                any Service Provider Fee in relation to the unbooked Shipment.
              </p>
              <p>
                Owner Operators are paid in accordance with Carrier payment terms and will be subject to payment dispute
                processes for Carriers.
              </p>
            </li>
            <li>
              <h3>Payment to Driver (Service Provider Remittance)</h3>
              <p>
                For each Shipment, FleetOperate will pay Driver the price listed to Driver when Driver accepted its
                Service Provider Fee. Driver is entitled to its Service Provider Fee only after completion of the
                Shipment, and the payment order to Driver will be made up to thirty (30) calendar days after
                FleetOperate’s receipt of the Proof of Delivery in accordance with this Agreement (it may take up to 5
                additional business days for Driver to receive the money due to third party payment processing).
                Completion of the Shipment for the purposes of payment to Driver is determined upon receipt of Proof of
                Delivery and acknowledgement of such Proof of Delivery by the Shipper through the FleetOperate platform.
                FleetOperate will only pay Driver if the Shipment Fee has been received in full and all Transportation
                Services related to that Shipment have been completed. Should the Shipment Fee not be paid by the
                Shipper, the Shipment will not be booked and the Driver will not be entitled to any Service Provider Fee
                in relation to the unbooked Shipment.
              </p>
              <p>
                Carriers who engage their own drivers outside of the FleetOperate Services are solely responsible for
                all payments to those drivers.
              </p>
            </li>
            <li>
              <h3>Additional General Payment Terms</h3>
              <p>
                All payments, except payments for Canadian Origin Shipments, will be in U.S. dollars. Canadian Origin
                Shipments will be in Canadian dollars. Fees and prices stated above are inclusive of all fuel costs,
                charges, and surcharges, except as otherwise provided herein. Carrier free standby time is one hundred
                twenty (120) minutes at origin (from the time the truck arrives, if within the scheduled pickup time)
                and one hundred twenty (120) minutes at every destination (from the time the truck checks-in at the
                facility, if within the scheduled delivery time). Shipper will incur detention charges thereafter,
                billable in fifteen (15)-minute increments ("Detention Charges"). Carrier shall bill FleetOperate (not
                Shipper) directly for such Detention Charges which charges FleetOperate will collect from Shipper as
                provided in this Subsection 9.4, below.
              </p>
              <p>
                Shipment Fees are non-refundable, once a Shipment is booked (unless FleetOperate, in its sole
                discretion, decides to issue a refund). Shippers using the FleetOperate Services agree to pay for any
                Shipments booked through the FleetOperate Services and not to circumvent payments for scheduled or
                completed Shipments in any way. Shipper agrees to pay Carrier a penalty (plus the agreed price per mile
                applicable after a 5-mile run) per Shipment if Shipper cancels the Shipment after Carrier has dispatched
                its truck to make the pickup. In addition to detention charges, Shipper agrees to pay Carrier a layover
                fee if Carrier or Driver is unable to pick up or deliver at the originally scheduled time because of
                some fault of Shipper, such as Shipper’s failure or inability to accommodate Carrier or Driver’s truck
                at its dock at the scheduled pick-up or delivery date and time or Shipper’s unilateral change (not
                accepted by Carrier) in the scheduled pickup or delivery date and/or time, and, as a result, Carrier or
                Driver’s truck and or Carrier or Driver is forced to layover and pick up or deliver the following
                work-day.
              </p>
              <p>
                Users agree they are responsible for the collection and/or payment of all taxes for which they are
                liable in any jurisdiction arising from their use of the FleetOperate Services, including any applicable
                duties, sales or use taxes, and other taxes that may be levied in connection with a transaction
                contemplated by this Agreement. FleetOperate is not responsible for collecting, reporting, paying, or
                remitting to User any such taxes.
              </p>
              <p>
                FleetOperate will bill Shipper, and Shipper shall pay FleetOperate, for all extra charges owed Carrier
                at the time of completion of the Shipment that were not accounted for and included in the Shipment Fee
                at the time of booking (e.g. cancellation fees, lumper charges, loading/unloading fees, detention
                charges, layover fees, rerouting charges, etc.). Shipper shall pay and FleetOperate shall collect these
                extra charges from Shipper in the manner provided in Subsections 3.1 and 9.4 of this Agreement and
                FleetOperate shall inform Shipper of the occurrence and amount of such extra charges as soon as they are
                incurred or as soon as reasonably foreseeable.
              </p>
              <p>
                Any amount that Shipper fails to pay FleetOperate when due will accrue interest at eighteen percent
                (18%) per annum or the maximum rate permitted by applicable law, whichever is less, from the due date
                until paid, Shipper shall be liable for all costs and expenses FleetOperate incurs in connection with
                collection of that Shipment Fee, including costs and expenses of a third-party collection agency and/or
                reasonable legal fees.
              </p>
              <p>
                FleetOperate may change pricing for the FleetOperate Services from time to time in its sole discretion
                by updating the Site and Mobile App and without any additional notice to Users, except with respect to
                booked or accepted Shipments. Your continued use and access to the FleetOperate Services after such
                amendment confirms your consent to be bound by the terms and pricing, as amended.
              </p>
              <p>
                For greater certainty, in all circumstances, FLEETOPERATE’S OBLIGATION TO PAY CARRIERS OR DRIVERS IS
                EXPRESSLY CONDITIONAL UPON FLEETOPERATE HAVING RECEIVED PAYMENT FROM SHIPPER, IN RESPECT OF SUCH
                PAYMENTS TO CARRIERS OR DRIVERS (AS THE CASE MAY BE). IN NO CIRCUMSTANCES, INCLUDING CIRCUMSTANCES WHERE
                ADDITIONAL ACCESSORIAL OR ANCILLARY CHARGES ARE INCURRED BY THE CARRIER OR DRIVER AS DESCRIBED IN
                SUBSECTION 3.1, SHALL FLEETOPERATE BE LIABLE TO SUCH CARRIERS OR DRIVERS, WHERE FLEETOPERATE HAS NOT
                RECEIVED PAYMENT FROM SHIPPER IN RESPECT OF THE SHIPMENT OR SERVICES FOR WHICH CARRIERS OR DRIVERS ARE
                CLAIMING PAYMENT.
              </p>
            </li>
            <li>
              <h3>Payment Disputes</h3>
              <p>
                FOR SHIPPERS: If Shipper notifies FleetOperate electronically, prior to payment to Carrier or Driver of
                their Service Provider Fee, that Shipper is in a dispute with Carrier or Driver over delay in delivery
                of or loss, damage, or shortage to the Shipment, supported by notation on the Proof of Delivery or
                delivery receipt uploaded to the FleetOperate Services, and Shipper requests FleetOperate not to pay the
                Service Provider Fee until the dispute is resolved, FleetOperate will withhold such payment for up to
                sixty (60) days from the completion of the Shipment to allow the parties time to resolve their dispute.
                After honoring such request, FleetOperate will pay the Service Provider Fee immediately after either it
                receives electronic instruction from Shipper to do so or the sixty (60)-day dispute resolution period
                has expired without receiving such instruction, whichever occurs first. If the Shipper instructs
                FleetOperate not to pay the Carrier or Driver, to pay an amount other than the Service Provider Fee, or
                to extend the dispute resolution period, upon receipt by FleetOperate of a signed agreement between the
                parties to the dispute evidencing agreement between these parties to such payment or extension,
                FleetOperate shall pay the Carrier or Driver or extend the dispute resolution period, as applicable. In
                no circumstances shall FleetOperate be liable for acting in accordance with such instructions.
              </p>
              <p>
                FOR CARRIERS: If Carrier notifies FleetOperate electronically, prior to payment to Driver of the
                Driver's Service Provider Fee, that Carrier is in a dispute with Driver over delay in delivery of or
                loss, damage, or shortage to the Shipment, supported by notation on the Proof of Delivery or delivery
                receipt uploaded to the FleetOperate Services, and Carrier requests FleetOperate not to pay the Driver's
                Service Provider Fee until the dispute is resolved, FleetOperate will withhold such payment for up to
                sixty (60) days from the completion of the Shipment to allow the parties time to resolve their dispute.
                After honoring such request, FleetOperate will pay the Driver's Service Provider Fee immediately after
                either it receives electronic instruction from Carrier to do so or the sixty (60)-day dispute resolution
                period has expired without receiving such instruction, whichever occurs first. If the Carrier instructs
                FleetOperate not to pay the Driver, to pay an amount other than the Driver's Service Provider Fee, or to
                extend the dispute resolution period, upon receipt by FleetOperate of a signed agreement between the
                Carrier and Driver evidencing agreement between the parties of the dispute to such payment or extension,
                FleetOperate shall pay the Driver or extend the dispute resolution period, as applicable. In no
                circumstances shall FleetOperate be liable for acting in accordance with such instructions.
              </p>
              <p>
                If Carrier is in a dispute with Shipper over extra charges incurred by Carrier in the course of the
                Shipment, the dispute shall be governed in accordance with the dispute procedures for ALL USERS below.
                In no circumstances will FleetOperate be responsible for any extra charges that are not accepted or not
                paid by a Shipper.
              </p>
              <p>
                FOR ALL USERS: Where disputes are not resolved between the parties within the timelines provided for in
                this Subsection 9.5, FleetOperate will pay the required Service Provider Fee to the Service Provide as
                outlined above. All parties agree to release FleetOperate from all liability respecting the payment
                dispute processes, including the payment of Service Provider Fees to the respective party. Any aggrieved
                party remaining after such payment has been made would then have the option of pursuing the other party
                privately for any recourse under their respective Shipping Contract or Driving Contract.
              </p>
              <p>
                Subject to the provisions related to payment disputes, as set out above in this Subsection 9.5, if at
                any time a dispute at any time a dispute, difference or question arises among a Driver, Carrier or
                Shipper concerning any question relating to their respective Shipping Contract or Driving Contract, the
                rights or liabilities of any of the parties thereto, or any other dispute involving either the
                interpretation of their respective Shipping Contract or Driving Contract or anything contained therein,
                then any such dispute, difference or question shall be settled by arbitration and the following
                provisions of this paragraph shall apply. The parties will refer the dispute to a single arbitrator if
                one can be mutually agreed upon by the parties to the dispute (provided that if the parties cannot agree
                upon a single arbitrator, then each party shall name one arbitrator within a further period of seven (7)
                days therefrom, and the arbitrators so named shall appoint one more arbitrator, and provided further
                that if one of the parties to the dispute refuses or neglects to appoint an arbitrator within the period
                herein set out, then the arbitrator appointed by the other party to the dispute shall sit and hear the
                dispute). The arbitrator(s) so appointed shall have a period of thirty (30) days to determine a
                resolution for the parties which shall be final and binding upon them. During the thirty (30) days'
                referral period, the parties shall each continue to perform their obligations under their respective
                Shipping Contract or Driving Contract, as applicable, and shall provide to the arbitrator(s) such
                materials as the arbitrator(s) may require from time to time to further assess the dispute and its
                resolution. The decision arrived at by a single arbitrator or a majority of the arbitrators, as the case
                may be, shall be binding upon all the parties to the arbitration and no appeal shall lie therefrom. The
                provisions of this paragraph shall be deemed to be a submission to arbitration within the provisions of
                <i>The Arbitration Act</i> (Manitoba).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>General Freight Loss or Damage Terms</h2>
          <p>
            The following terms of this Section 10 <u>apply to all Shipments</u>, regardless of whether they are US
            Origin Shipments or Canadian Origin Shipments. For terms specific to US Origin Shipments and Canadian Origin
            Shipments, please see Sections 11 and 12, respectively.
          </p>
          <ol class="subheading-list">
            <li>
              <h3>Cargo Loading, Unloading, and Securement</h3>
              <p>
                Shipper is solely responsible for loading, unloading, and securing cargo it transports under this
                Agreement, unless Shipper expressly requires Carrier to do so and indicated accordingly when it booked
                the Shipment, and Shipper pays Carrier the corresponding costs and fees, if any.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>US Carrier Freight Loss or Damage</h2>
          <p>The provisions of this Section 11 apply to US Origin Shipments.</p>
          <p>
            Carrier agrees to assume full liability for the prompt, safe transportation of all Shipments under this
            Agreement, including compliance with applicable temperature requirements. CARRIER AGREES TO BE RESPONSIBLE
            FOR ALL LOSS, DAMAGE, DELAY, DESTRUCTION, THEFT OR LIABILITY OF WHATEVER NATURE ARISING FROM THE
            TRANSPORTATION SERVICES IN ACCORDANCE WITH THE APPLICABLE PROVISIONS OF THE CARMACK AMENDMENT TO THE
            INTERSTATE COMMERCE ACT, 49 U.S.C. § 14706. Carrier’s liability to Shipper and to any involved consignor or
            consignee shall be the full invoice value of the goods transported, without limitation. Carrier shall not be
            liable for incidental, special, or consequential damages resulting from or related to loss, damage, or delay
            of any Shipment or its cargo. Signatures on Bills of Lading or receipts issued by Carrier shall not
            constitute Shipper’s written acceptance of Carrier’s liability limitations or other changes in these terms
            and conditions, whether appearing in Carrier’s bill of lading or tariffs or on its website. Carrier and
            Shipper agree that pursuant to 49 U.S.C. § 14101(b) Carrier expressly waives all rights and remedies under
            legislation, including Title 49 of the U.S. Code that conflict with the terms and conditions of this
            Agreement.
          </p>
          <ol class="subheading-list">
            <li>
              <h3>US Cargo Claims Filing</h3>
              <p>
                In the event of loss, damage, or delay, Shipper shall file a claim directly with Carrier – and not with
                FleetOperate – within nine (9) months of delivery, and Carrier shall settle or otherwise dispose of such
                claim, granting or denying the claim in whole or in part, within sixty (60) days of receipt of the
                claim. Carrier hereby assumes all other terms and conditions set forth in 49 U.S.C. § 14706. Shipper
                must commence any lawsuit arising from such claim within eighteen (18) months from disallowance or
                denial of all or any part of such claim. Carrier shall not dispose of damaged or rejected product
                without the prior written consent of Shipper.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Canadian Carrier Freight Loss or Damage</h2>
          <p>The provisions of this Section 12 apply to Canadian Origin Shipments.</p>
          <p>
            Unless the Shipper declared a value for the Shipment when it booked the Shipment, Carrier’s liability for
            loss of or damage to any commodities being transported under this Agreement, whether or not the loss or
            damage results from negligence or willful misconduct, shall be restricted to the <u>lesser</u> of:
          </p>
          <ol class="default-list">
            <li>
              the value of the commodities provided by the Shipper at the place and time of shipment, including Shipment
              Fees, if paid; and
            </li>
            <li>
              the dollar value per kilogram computed on the total weight of the Shipment, as provided for in the
              applicable bill of lading legislation or equivalent legislation for the jurisdiction of the cargo loss.
            </li>
          </ol>
          <p>
            Shippers should be aware that if the value of the Shipment is not declared by them at the time of booking,
            their ability to recover loss or damage resulting will be limited to the <u>lesser</u> of the above.
          </p>
          <p>
            Where the Shipper declared a value for the Shipment when it booked the Shipment, the Carrier shall assume
            liability for loss or damage up to the specified declared value amount (the “Declared Value”) and in such a
            case, the Carrier’s liability for loss of or damage to any commodities being transported in such Shipment,
            whether or not the loss or damage results from negligence or willful misconduct, shall be restricted to the
            <u>lesser</u> of:
          </p>
          <ol class="default-list">
            <li>
              the value of the commodities at the place and time of shipment, including the freight and other charges if
              paid; and
            </li>
            <li>the Declared Value.</li>
          </ol>
          <p>
            Shippers should be aware that their ability to recover loss or damage resulting from the above will be
            limited to the <u>lesser</u> of the above.
          </p>
          <p>
            IN NO CIRCUMSTANCES WILL FLEETOPERATE BE LIABLE FOR LOSS OR DAMAGE TO ANY COMMODITIES BEING TRANSPORTED
            UNDER THIS AGREEMENT, REGARDLESS OF WHETHER SUCH AMOUNT EXCEEDS ANY LIMITS SET OUT IN THIS SECTION.
          </p>
          <ol class="subheading-list">
            <li>
              <h3>Canadian Cargo Claims Filing</h3>
              <p>
                In the event of loss, damage, or delay, Shipper shall file a claim directly with Carrier – and not with
                FleetOperate – within either sixty (60) days after delivery of the commodities, or in the case of
                failure to deliver the commodities, within nine (9) months after the date of Shipment, and Carrier shall
                settle or otherwise dispose of such claim, granting or denying the claim in whole or in part, within
                sixty (60) days of receipt of the claim. Shipper must commence any lawsuit arising from such claim
                within nine (9) months after the date of the Shipment. Carrier shall not dispose of damaged or rejected
                product without the prior written consent of Shipper.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Compliance with Laws</h2>
          <p>
            FleetOperate is based in Manitoba, Canada and the FleetOperate Services are intended for use by Users
            located in Canada and the United States. You as a User represent and warrant that you are not located in any
            jurisdiction where the use of the FleetOperate Services is not permitted. If you access the FleetOperate
            Services from outside of Canada or the United States, you do so at your own risk and are responsible for
            compliance with local laws of your jurisdiction. For Users in the United States, you represent and warrant
            that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any
            U.S. Government list of prohibited or restricted parties.
          </p>
          <p>
            User agrees that they shall at all times comply with all federal, state, provincial, territorial, municipal
            and local laws, ordinances, regulations, rules, by-laws and orders that are applicable to the operation of
            its business, and this Agreement and its performance hereunder. Without limiting the generality of the
            foregoing, User shall at all times, at its own expense, obtain and maintain all certifications, credentials,
            authorizations, licenses, consents and permits necessary to conduct that portion of its business relating to
            the exercise of its rights and the performance of its obligations under this Agreement and comply with all
            orders and regulations imposed by any governmental, supervisory or other relevant authority and all
            employee-related laws, ordinances, rules, regulations and orders, including with respect to wages, hours,
            collective bargaining, occupational health and safety, workers' hazardous materials, employment standards,
            pay equity, discrimination, workers' compensation and unfair labour practices.
          </p>
        </li>
        <li>
          <h2>Disclaimer of Warranty</h2>
          <p>
            FLEETOPERATE MAKES EVERY REASONABLE EFFORT TO KEEP ITS FLEETOPERATE PLATFORM UP, BUG-FREE, AND SAFE, BUT ALL
            USERS USE IT AT THEIR OWN RISK. THE FLEETOPERATE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE,” AND
            FLEETOPERATE (AND ITS SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, AND NON-INFRINGEMENT. FLEETOPERATE (AND ITS SUPPLIERS) MAKE NO
            WARRANTY THAT THE FLEETOPERATE SERVICES: (A) WILL MEET USER’S REQUIREMENTS OR EXPECTATIONS; (B) WILL BE
            AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) WILL BE ACCURATE, RELIABLE, FREE OF
            VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE; OR (D) RESULT IN ANY REVENUE, PROFITS, OR COST
            REDUCTION. THE FLEETOPERATE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN
            THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. FLEETOPERATE IS NOT RESPONSIBLE FOR ANY DELAYS,
            DELIVERY FAILURES, OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.
          </p>
          <p>
            FLEETOPERATE USES TECHNOLOGY TO CONNECT SHIPPERS, DRIVERS, AND CARRIERS, BUT DOES NOT AND DOES NOT INTEND TO
            PROVIDE SHIPPING OR TRANSPORTATION SERVICES AS A CARRIER, COURIER, DRIVER OR SHIPPER. IT IS UP TO THE
            THIRD-PARTY CARRIER OR DRIVER, AS THE CASE MAY BE, TO PROVIDE SHIPPING AND TRANSPORTATION SERVICES WHICH MAY
            BE SCHEDULED THROUGH THE USE OF THE FLEETOPERATE SERVICES. FLEETOPERATE HAS NO RESPONSIBILITY OR LIABILITY
            FOR ANY SHIPPING OR TRANSPORTATION SERVICES PROVIDED (OR NOT PROVIDED, AS THE CASE MAY BE) TO SHIPPERS BY
            SUCH THIRD-PARTY CARRIERS OR DRIVERS. NEITHER SHIPPERS, CARRIERS NOR DRIVERS SHALL BE DEEMED TO BE
            SUBCONTRACTORS OR EMPLOYEES OF FLEETOPERATE FOR ANY REASON. SHIPPERS, CARRIERS AND DRIVERS ARE, AND SHALL
            REMAIN AT ALL TIMES, INDEPENDENT CONTRACTORS WITH RESPECT TO FLEETOPERATE AND THE FLEETOPERATE SERVICES
            PROVIDED UNDER THIS AGREEMENT. NOTHING IN THIS AGREEMENT OR ANY SHIPPING DOCUMENT SHALL BE CONSTRUED TO
            CREATE A LEGAL PARTNERSHIP OR JOINT VENTURE BETWEEN FLEETOPERATE AND ANY OF THE PARTIES. AS AN INDEPENDENT
            CONTRACTOR, CARRIER OR DRIVER, AS THE CASE MAY BE, ASSUMES FULL RESPONSIBILITY FOR THE PAYMENT OF ALL TAXES,
            INSURANCE, PENSION AND RELATED MATTERS APPLICABLE TO ITS EMPLOYEES. EXCEPT AS OTHERWISE PROVIDED HEREIN,
            THIS AGREEMENT IS NONEXCLUSIVE AND SHALL NOT BE INTERPRETED TO LIMIT ANY PARTY’S RIGHT TO OBTAIN PRODUCTS OR
            SERVICES FROM OTHER SOURCES.
          </p>
          <p>
            DRIVING WHILE USING A CELL OR SMART PHONE IS DANGEROUS AND IS AGAINST THE LAW IN MANY JURISDICTIONS. DO NOT
            USE THE MOBILE APP OR SITE WHILE DRIVING. IF YOU USE THE MOBILE APP OR SITE WHILE DRIVING, YOU DO SO AT YOUR
            OWN RISK.
          </p>
          <p>
            FLEETOPERATE DOES NOT VERIFY THE SUITABILITY, LEGALITY, REGULATORY COMPLIANCE, QUALITY, OR ABILITY OF ANY
            CARRIER, DRIVER, SHIPPER, SHIPPED ITEMS, AND SHIPPING OR TRANSPORTATION SERVICES SCHEDULED THROUGH THE USE
            OF THE FLEETOPERATE SERVICES, AND FLEETOPERATE MAKES NO WARRANTY, EXPRESS OR IMPLIED, REGARDING THE
            FOREGOING. BY USING THE FLEETOPERATE SERVICES, THE USER OR THE SHIPMENTS MAY BE EXPOSED TO SITUATIONS THAT
            ARE POTENTIALLY DANGEROUS, OFFENSIVE, HARMFUL, UNSAFE, OR OTHERWISE OBJECTIONABLE. USERS USE THE
            FLEETOPERATE SERVICES AT THEIR OWN RISK. USERS’ INTERACTIONS WITH OTHER USERS AND THIRD PARTIES ARE SOLELY
            BETWEEN THOSE USERS AND SUCH USER OR THIRD PARTY. USER AGREES THAT FLEETOPERATE WILL NOT BE RESPONSIBLE FOR
            ANY LOSS OR DAMAGE INCURRED AS THE RESULT OF ANY SUCH INTERACTIONS. IF THERE IS A DISPUTE BETWEEN YOU AS A
            USER AND ANY OTHER USER OR THIRD PARTY, FLEETOPERATE IS UNDER NO OBLIGATION TO BECOME INVOLVED, EXCEPT AS
            REQUIRED BY LAW.
          </p>
          <p>
            USER HEREBY WAIVES AND RELEASES FLEETOPERATE AND ITS SUPPLIERS, OFFICERS, DIRECTORS, SHAREHOLDERS,
            EMPLOYEES, AGENTS, CONTRACTORS, AND ALL OF THEIR SUCCESSORS, HEIRS, PERSONAL REPRESENTATIVES AND ASSIGNS
            (COLLECTIVELY “FLEETOPERATE/SUPPLIER GROUP”) FROM, AND HEREBY WAIVES AND RELINQUISHES, EACH AND EVERY PAST,
            PRESENT AND FUTURE DISPUTE, CLAIM, CONTROVERSY, DEMAND, RIGHT, OBLIGATION, LIABILITY, ACTION AND CAUSE OF
            ACTION UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
            STRICT LIABILITY AND OTHERWISE, FOR ANY LOSS, OR EXPENSE (INCLUDING PERSONAL INJURIES, DEATH, PROPERTY
            DAMAGE AND REASONABLE LEGAL FEES) (EACH, A “CLAIM”) ARISING FROM (I) USER’S USE OF THE FLEETOPERATE
            SERVICES; (II) USER CONTENT; (III) INTERACTION WITH ANY OTHER USER; (IV) VIOLATION OF THIS AGREEMENT; (V)
            VIOLATION OF APPLICABLE LAWS OR REGULATIONS; OR (VI) USER’S SHIPMENT CONTENTS (IF YOU ARE A SHIPPER) OR
            USER’S TRANSPORTATION SHIPMENT SERVICES (IF YOU ARE A CARRIER OR DRIVE), OR IN ANY WAY RELATED TO OTHER
            USERS OR THIRD PARTIES, EVEN IF SUCH CLAIM RESULTS IN WHOLE OR IN PART FROM THE NEGLIGENCE OR WILLFUL
            MISCONDUCT OF FLEETOPERATE/SUPPLIER GROUP. IF YOU AS A USER ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE
            CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES
            NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
            EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
            THE DEBTOR.”
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES. ACCORDINGLY, THE ABOVE EXCLUSION MAY
            NOT APPLY TO YOU.
          </p>
        </li>
        <li>
          <h2>Limitation of Liability</h2>
          <p>
            IN NO EVENT WILL FLEETOPERATE/SUPPLIER GROUP BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS
            SUBJECT MATTER FOR (A) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR DIMINUTION IN VALUE; (B)
            IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE FLEETOPERATE SERVICES; (C) LOSS, DAMAGE,
            CORRUPTION OR RECOVERY OF DATA; (D) BREACH OF DATA OR SYSTEM SECURITY (INCLUDING FINES LEVIED BY A
            GOVERNMENTAL AUTHORITY); OR (E) CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, AGGRAVATED, PUNITIVE OR
            EXEMPLARY DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
            DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED
            OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, FLEETOPERATE
            SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (I) YOUR USE OF OR RELIANCE ON THE
            SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (II) ANY TRANSACTION OR RELATIONSHIP BETWEEN
            YOU AND ANY THIRD PARTY PROVIDER, EVEN IF FLEETOPERATE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            FLEETOPERATE SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND
            FLEETOPERATE’S REASONABLE CONTROL. IN NO EVENT SHALL FLEETOPERATE/SUPPLIER GROUP'S TOTAL LIABILITY TO YOU IN
            CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE
            LESSER OF FIVE HUNDRED CANADIAN DOLLARS ($500 CAD) OR ALL FEES PAID BY YOU TO FLEETOPERATE, IN THE LAST SIX
            (6) MONTHS PRECEDING THE INCIDENT THAT GAVE RISE TO THE LIABILITY. THE EXISTENCE OF MORE THAN ONE CLAIM WILL
            NOT ENLARGE THIS LIMIT.
          </p>
          <p>
            FLEETOPERATE DOES NOT CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY USER CONTENT OR THIRD-PARTY CONTENT
            AVAILABLE ON OR LINKED TO BY THE SERVICES (INCLUDING INFORMATION ON THE SERVICES PROVIDER BY USERS OR BY
            SHIPPERS). FLEETOPERATE CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICES OR SERVERS ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL
            DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AS A USER, AND YOU MAY ALSO
            HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
          </p>
          <p>
            Each User acknowledges and agrees that the fees payable to FleetOperate pursuant to this Agreement have been
            and will be negotiated based upon a specific risk allocation between FleetOperate and the User, and that
            FleetOperate would not enter into this Agreement but for the specific understanding reached with the User as
            to certain risks, as set forth in this Section.
          </p>
        </li>
        <li>
          <h2>Indemnification</h2>
          <p>
            User agrees to indemnify, defend and hold FleetOperate/Supplier Group harmless from any and all claims,
            demands, losses, liabilities, and expenses (including legal fees), arising out of or in connection with
            either your use of the FleetOperate Services or goods obtained through your use of the FleetOperate
            Services, or your breach or violation of any of the terms of this Agreement.
          </p>
          <p>
            In addition to other indemnities expressly provided in this Agreement, each User agrees to defend, indemnify
            (including for costs and legal fees), and hold FleetOperate/Supplier Group, harmless, from and against any
            claim, action, demand, loss, and expense (including costs and reasonable legal fees), made or suffered by
            any third party due to or arising out of User’s:
          </p>
          <ol class="default-list">
            <li>use of the FleetOperate Services;</li>
            <li>User Content;</li>
            <li>interaction with any other User;</li>
            <li>violation of this Agreement;</li>
            <li>violation of applicable laws or regulations; or</li>
            <li>
              User’s Shipment contents (if you are a Shipper) or User’s Transportation Services (if you are a Carrier or
              Driver),
            </li>
          </ol>
          <p>
            FleetOperate reserves the right, at User’s expense, to assume the exclusive defense and control of any
            matter for which User is required to indemnify FleetOperate Group, and User agrees to cooperate with
            FleetOperate’s defense of these claims. User agrees not to settle any matter without the prior written
            consent of FleetOperate. FleetOperate will use reasonable efforts to notify User of any such claim, action,
            or proceeding upon becoming aware of it.
          </p>
        </li>
        <li>
          <h2>Privacy</h2>
          <p>
            By submitting your personal information and using the FleetOperate Services, you consent to the collection,
            use, reproduction, hosting, transmission and disclosure of any User Content in compliance with our Privacy
            Policy, found at <u><a (click)="onNavigateToPrivacyPolicy()">this link</a></u
            >, as we deem necessary for use of the FleetOperate Services, or for any other purposes that or set out in
            our Privacy Policy, or required or permitted by law.
          </p>
        </li>
        <li>
          <h2>Intellectual Property Rights and Ownership</h2>
          <p>
            You understand and agree that the Site and Mobile App and their entire contents, features, and
            functionality, including, but not limited to, all information, software, code, text, displays, graphics,
            photographs, video, audio, design, presentation, selection, and arrangement, are owned by FleetOperate, its
            licensors, or other providers of such material and are protected in all forms by intellectual property laws
            including without limitation, copyright, trade-mark, patent, trade secret, and any other proprietary rights.
          </p>
          <p>
            FleetOperate and all related names, logos, product and service names, designs, images and slogans are
            trade-marks of FleetOperate or its affiliates or licensors. You must not use such marks without the prior
            written permission of FleetOperate. Other names, logos, product and service names, designs, images and
            slogans mentioned, or which appear on the Site or Mobile App are the trade-marks of their respective owners.
            Use of any such property, except as expressly authorized, shall constitute an infringement or violation of
            the rights of the property owner and may be a violation of federal or other laws and could subject the
            infringer to legal action.
          </p>
          <p>
            You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create
            derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the
            material on the Site or Mobile App, in any form or medium whatsoever except:
          </p>
          <ol class="default-list" type="a">
            <li>
              your computer and browser may temporarily store or cache copies of materials being accessed and viewed;
              and
            </li>
            <li>
              a reasonable number of copies may be printed keeping any proprietary notices thereon, which may only be
              used for lawful use and not for further reproduction, publication, or distribution of any kind on any
              medium whatsoever.
            </li>
          </ol>
          <p>
            Users are not permitted to modify copies of any materials from the Site or Mobile App nor delete or alter
            any copyright, trade-mark, or other proprietary rights notices from copies of materials from the Site or
            Mobile App.
          </p>
          <p>
            If you print off, copy or download any part of our the Site or Mobile App in breach of this Agreement, your
            right to use the FleetOperate Services will cease immediately and you must, at our option, return or destroy
            any copies of the materials you have made. You have no right, title, or interest in or to the FleetOperate
            Services or to any content on the Site or Mobile App, and all rights not expressly granted are reserved by
            FleetOperate. Any use of the FleetOperate Services not expressly permitted by this Agreement is a breach of
            this Agreement and may infringe or violate copyright, trade-mark, and other intellectual property or other
            proprietary laws.
          </p>
        </li>
        <li>
          <h2>Practices regarding Usage and Storage</h2>
          <p>
            Storage or Deletion of Content: You agree that we have no responsibility or liability for the deletion of,
            or the failure to store or to transmit, any information maintained by us, including, without limitation,
            your User Content and FleetOperate content ("Our Content").
          </p>
          <p>
            Limits on Storage: We retain the right to create limits on use and storage, at our sole discretion, at any
            time, with or without notice.
          </p>
          <p>
            Our Access to Stored Content: We also reserve the right to access, read, preserve, and disclose any
            information as we reasonably believe is necessary, including, without limitation, User Content, to: (a)
            satisfy any applicable law, regulation, legal process or governmental request; (b) enforce this Agreement,
            including investigation of potential violations hereof; (c) detect, prevent, or otherwise address fraud,
            security or technical issues; (d) respond to User support requests; or (e) protect our rights, property or
            safety, Users or the public. We will not be responsible or liable for the exercise or non-exercise of our
            rights under this Agreement.
          </p>
          <p>
            Monitoring: We have the right to access the FleetOperate Services, Our Content and User Content at any time,
            to maintain their effective operation, to provide upgrades to the Site, Mobile App or other system
            components and to review the your use of the FleetOperate Services, Our Content and User Content. We reserve
            the right, but do not assume the responsibility or obligation, to monitor, view and audit transactions and
            communications (inbound and outbound, including quotes and Shipment requests), in our sole and absolute
            discretion, in order to manage the FleetOperate Services and for such other purposes as we shall deem
            necessary or advisable. If we determine, in our sole and absolute discretion, that you have breached or will
            breach a term or condition of this Agreement, we may cancel any quote, Shipment or related transaction, or
            take any other action to restrict access to or the availability of any material that may be considered
            objectionable, without any liability to you or any third party. Further, in such event, we may, in our sole
            and absolute discretion, immediately terminate this Agreement and your access to the FleetOperate Services,
            all without notice to you.
          </p>
          <p>
            Industry Analysis: We reserve the non-expiring right to use and disclose (for commercial purposes or
            otherwise) the textual and numerical statistical portion of all data in the FleetOperate Services at any
            time to conduct analysis for industry trends, provided that such data shall not be singularly isolated and
            labeled as a particular account holder’s data.
          </p>
        </li>
        <li>
          <h2>Term and Termination</h2>
          <p>
            Subject to the provisions of this Section 20, this Agreement will remain in full force and effect while as
            User you use the FleetOperate Services (the "Term"). FleetOperate may at any time in its sole discretion
            terminate this Agreement with User, without provision of notice, if (i) User has breached any provision of
            this Agreement (or has acted in a manner that objectively shows that User does not intend to, or is unable
            to, comply with this Agreement); (ii) any representation or warranty made by the User was untrue, inaccurate
            or misleading at the time this Agreement was entered into; (iii) FleetOperate is required to do so by law
            (for example, where the provision of the FleetOperate Services is, or becomes, unlawful); (iv) FleetOperate
            has elected to discontinue the FleetOperate Services as described above; or (v) FleetOperate deems, in its
            sole discretion, that User’s performance as a Shipper, Carrier, or Driver is poor and may adversely affect
            the credibility or effectiveness of the FleetOperate Services. FleetOperate may terminate or suspend your
            access to all or part of the FleetOperate Services for any or no reason, including, without limitation, any
            violation of this User Agreement, without notice to you.
          </p>
          <p>
            Upon termination of this Agreement, User’s account and right to access and use the FleetOperate Services
            will terminate immediately. Where a Shipment is in progress at the time of termination, the Shipper,
            Carrier, and Driver shall complete the Shipment, as applicable, and the User whose Agreement has been
            terminated shall send any documents related to such Shipment to FleetOperate directly, at the following
            email: support@fleetoperate.com. In respect of in progress Shipments, the Agreement will not terminate until
            such Shipments are completed, and the terms of the Agreement will continue to apply to any in progress
            Shipments.
          </p>
          <p>
            Any payment entitlements that have accrued as at the time of termination, including Service Provider Fees
            for completed Shipments, will be paid in accordance with this Agreement, as part of the termination.
          </p>
        </li>
        <li>
          <h2>Amendment to Terms</h2>
          <p>
            This Agreement is subject to occasional revision by FleetOperate, and if FleetOperate makes any material
            changes, it will attempt to notify you as a User by providing you an option to accept the new terms before
            your next use of the FleetOperate Services or by sending you an e-mail to the last email address you
            provided to us (if any) and/or by prominently posting notice of the changes on the Site or Mobile App. Any
            changes to this Agreement will be effective upon the earlier of (i) the date you accept the new terms or
            (ii) thirty (30) calendar days following FleetOperate’s transmission of an email notice to you (if
            applicable) or (iii) thirty (30) calendar days following FleetOperate’s posting of notice of the changes on
            the Site or Mobile App. These changes will be effective immediately for new Users of the FleetOperate
            Services. As a User, you are responsible for providing FleetOperate with your most current email address. In
            the event the last email address you provided FleetOperate is not valid, or for any reason is not capable of
            delivering to you the notice described above, FleetOperate’s dispatch of the email containing such notice
            will nonetheless constitute effective notice of the changes described in the notice. Your continued use of
            the FleetOperate Services following notice of such changes shall indicate your acknowledgement of such
            changes and agreement to be bound by the terms and conditions of such changes.
          </p>
        </li>
        <li>
          <h2>Governing Law and Jurisdiction</h2>
          <p>
            The FleetOperate Services, including the Site and Mobile App, and this Agreement will be governed by and
            construed in accordance with the laws of the province of Manitoba and the federal laws of Canada applicable
            therein, without giving effect to any choice or conflict of law provision, principle or rule (whether of the
            laws of Manitoba or any other jurisdiction) and notwithstanding your domicile, residence or physical
            location.
          </p>
          <p>
            Subject to Subsection 9.5, any action or proceeding arising out of or relating to the FleetOperate Services
            and under this Agreement will be instituted in the courts of the province of Manitoba, and each party
            irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive
            any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such
            courts.
          </p>
        </li>
        <li>
          <h2>Consent to Electronic Communication</h2>
          <p>
            When you use the FleetOperate Services or send email or text messages to us, you are communicating with us
            electronically. You consent to receive communications from us electronically. We will communicate with you
            by email, text messages or by posting notices on the Site or Mobile App. You agree that all agreements,
            notices, disclosures and other communications that we provide to you electronically, satisfy any legal
            requirement that such communications be in writing and/or signed.
          </p>
        </li>
        <li>
          <h2>Waiver</h2>
          <p>
            No failure by FleetOperate to exercise, or delay in exercising, any right, remedy, power or privilege
            arising from this Agreement operates, or may be construed, as a waiver by FleetOperate thereof. No single or
            partial exercise of any right, remedy, power or privilege by FleetOperate hereunder precludes any other or
            further exercise thereof or the exercise of any other right, remedy, power or privilege by FleetOperate.
          </p>
          <p>
            As a User, you agree to waive any right you may have to: (a) a trial by jury; and (b) commence or
            participate in any class action against FleetOperate related to your use of the FleetOperate Services, the
            exchange of electronic documents between us or this Agreement and, where, applicable, you also agree to opt
            out of any class proceedings against us.
          </p>
        </li>
        <li>
          <h2>Survival</h2>
          <p>
            Any provision of this Agreement that must survive to fulfill its essential purpose (whether expressly stated
            as such or not) and any obligation you have to pay fees incurred before termination will survive the
            termination of this Agreement.
          </p>
        </li>
        <li>
          <h2>Severability</h2>
          <p>
            If any term or provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction, such
            invalidity, illegality or unenforceability shall not affect any other term or provision of this Agreement or
            invalidate or render unenforceable such term or provision in any other jurisdiction.
          </p>
        </li>
        <li>
          <h2>Entire Agreement</h2>
          <p>
            This Agreement, the agreements which are expressly referenced in this Agreement, and our Privacy Policy
            constitute the sole and entire agreement between you and FleetOperate regarding the FleetOperate Services
            and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both
            written and oral, regarding such subject matter.
          </p>
        </li>
        <li>
          <h2>Contact Information</h2>
          <p>
            If you have any questions about this Agreement, or the FleetOperate Services generally, please contact us
            at:
          </p>
          <p>
            <strong>
              FleetOperate Inc.
              <br />
              201 Portage Avenue, 18th Floor,
              <br />
              Winnipeg, MB, R3B 3K6, Canada
              <br />
              info@fleetoperate.com
            </strong>
          </p>
        </li>
      </ol>
      <p style="text-align: right;">
        <strong><i>Last Revised: January 19, 2021</i></strong>
      </p>
    </div>
  </div>
</div> -->

<div class="terms-container" fxLayoutAlign="start" fxLayout="column">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
    <span fxFlex class="title">Terms and Conditions</span>
  </div>

  <div class="iframe-container" fxLayout="column">
    <iframe 
      src="https://fleetoperate.com/terms.html" 
      class="terms-iframe"
      frameborder="0"
    ></iframe>
  </div>
</div>