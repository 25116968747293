<div class="injured-details-container">
  <h2 class="mat-h2">Injured Details</h2>
  <form [formGroup]="form">
    <mat-accordion>
      <div formArrayName="injuredPersons">
        <mat-expansion-panel *ngFor="let person of injuredPersons.controls; let i = index" [formGroupName]="i">
          <mat-expansion-panel-header>
            <mat-panel-title> Injured Person {{ i + 1 }} </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="removeRow(i)" aria-label="Remove Injured Person">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Name Field -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Name" />
          </mat-form-field>

          <!-- Address Field -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address" placeholder="Address" />
          </mat-form-field>

          <!-- Contact Number Field -->
          <mat-form-field appearance="outline" class="form-field full-width">
            <mat-label>Contact Number</mat-label>
            <input matInput formControlName="contactNumber" placeholder="Contact Number" />
          </mat-form-field>

          <!-- Type Field -->
          <mat-radio-group formControlName="type" class="radio-group">
            <mat-radio-button value="pedestrian">Pedestrian</mat-radio-button>
            <mat-radio-button value="insured">Insured Vehicle</mat-radio-button>
            <mat-radio-button value="tp">TP Vehicle</mat-radio-button>
          </mat-radio-group>

          <!-- Description Field -->
          <mat-form-field appearance="outline" class="form-field full-width margin-top-sm">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              formControlName="description"
              placeholder="Description"
              maxlength="200"
              data-test="damageDesc-form-field"
              matTextareaAutosize
              matAutosizeMinRows="6"
            ></textarea>
          </mat-form-field>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

    <button mat-raised-button color="primary" (click)="addRow()" class="add-row">
      + Add Injured Person
    </button>
  </form>
</div>
