import { Component, OnInit } from '@angular/core';
import { DriverQualificationService } from '../../service/driver-qualification.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report-incident',
  templateUrl: './report-incident.component.html',
  styleUrls: ['./report-incident.component.scss']
})
export class ReportIncidentComponent implements OnInit {
  carrierCompanyName: any;
  loading = false;
  currentStep = 1;
  totalSteps = 6;

  constructor(
     private driverQualificationService: DriverQualificationService,
     private location: Location,
  ) { }

  ngOnInit(): void {
    this.carrierCompanyName = this.driverQualificationService.dqfData?.carrierCompanyName;
  }

  backAction(): void {
    this.location.back();
  }


  onNextStep() {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    }
  }


}
