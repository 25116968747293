<div fxLayoutAlign="start" fxLayout="column" class="dqf-review page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">Report Incident for {{ carrierCompanyName }}</div>
  </div>
  <div class="new-claim-container">
    <mat-horizontal-stepper [linear]="true" #stepper *ngIf="!loading">
      <mat-step *ngFor="let step of [].constructor(totalSteps); let i = index">
        <ng-container *ngIf="i === 0">
          <app-accident-details></app-accident-details>
        </ng-container>
        <ng-container *ngIf="i === 1">
          <app-authority-contacted></app-authority-contacted>
        </ng-container>
        <ng-container *ngIf="i === 2">
          <app-property-damage-detail></app-property-damage-detail>
        </ng-container>
        <ng-container *ngIf="i === 3">
          <app-injured-details></app-injured-details>
        </ng-container>
        <ng-container *ngIf="i === 4">
          <app-witness-details></app-witness-details>
        </ng-container>
        <ng-container *ngIf="i === 5" class="document">
          <div fxLayout="column">
            <h2 class="mat-h2">Upload Supporting Documents</h2>
            <button fxLayoutAlign="end" mat-raised-button color="primary" class="upload margin-top-sm margin-bottom-sm">
              Upload Document
            </button>
            <p class="empty-msg margin-top-md">No Documents uploaded yet</p>
          </div>
        </ng-container>

        <div class="step-actions margin-top-sm">
          <button mat-button matStepperPrevious *ngIf="i !== 0">Back</button>
          <button mat-raised-button color="primary" matStepperNext (click)="onNextStep()">
            {{ i === totalSteps - 1 ? 'Submit' : 'Next' }}
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
